import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";
import {Box, Button} from "@mui/material";

interface Props {
  onCommentSubmit: (data: FieldValues) => void;
}

const AddPartnerCommentForm = (props: Props) => {
  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      comment: ''
    }
  });

  const onPartnerCommentSubmit = (data: FieldValues) => {
    props.onCommentSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(onPartnerCommentSubmit)}>
      <Box sx={{display: 'flex'}}>
        <BasicFormControl control={control} name='comment' displayText='Comentariu'
                          rules={{required: {value: true, message: 'Campul este obligatoriu'}}}
                          errors={errors}/>
      </Box>
      <Button sx={{width: '100%'}} variant='contained' type='submit'>Submit</Button>
    </form>
  )
}

export default AddPartnerCommentForm;