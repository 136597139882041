import ReactPDF, {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import React, {Fragment} from "react";
import {ProductDto} from "../../types/ProductDto";
import {calculatePercentage} from "../../utils/price";
// @ts-ignore
import arial from '../../assets//fonts//ARIAL.TTF'
// @ts-ignore
import arialmtbold from '../../assets//fonts//Arial-BoldMT.otf'
// @ts-ignore
import verdanabold from '../../assets//fonts//verdanab.ttf'
import Svg = ReactPDF.Svg;
import Line = ReactPDF.Line;

Font.register({
  family: 'arial',
  fonts: [
    {
      src: arial,
    }]
},);
Font.register({
  family: 'arialmtbold',
  fonts: [
    {
      src: arialmtbold,
    }]
});
Font.register({
  family: 'verdanabold',
  fonts: [
    {
      src: verdanabold,
    }]
});

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flexGrow: 1,
  },
  pageContainer: {
    paddingVertical: 19,
    paddingHorizontal: 19,
    paddingBottom: 40
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  logo: {
    width: '20%',
    height: 'auto',
    marginTop: 60
  },
  logoContainer: {
    display: 'flex',
    width: '60%',
    marginTop: 15
  },
  columnText: {
    display: 'flex'
  },
  boldTitleText: {
    fontFamily: 'arialmtbold',
    fontWeight: 'bold',
    fontSize: 23.5,
    color:'rgb(51,51,51)'
  },
  boldSubtitleText: {
    fontFamily: 'arialmtbold',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
    paddingTop: 8,
    color:'rgb(51,51,51)'
  },
  boldSubtitleText2: {
    fontFamily: 'verdanabold',
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 5,
    paddingTop: 8,
    color:'rgb(51,51,51)'
  },
  titleTextWhite: {
    fontFamily: 'Roboto',
    fontSize: 18,
    color: 'white'
  },
  boldTextWhite: {
    fontFamily: 'arialmtbold',
    // fontWeight: 'demibold',
    fontSize: 11.5,
    color: 'white'
  },
  boldTextWhiteCurrency: {
    fontFamily: 'arialmtbold',
    // fontWeight: 'demibold',
    fontSize: 15.5,
    color: 'white'
  },
  textLabel: {
    fontFamily: 'arial',
    fontWeight: 'thin',
    fontSize: 9,
    color:'rgb(51,51,51)'
  },
  textNormal: {
    fontFamily: 'arial',
    fontWeight: 'thin',
    fontSize: 10,
    color:'rgb(0,0,0)'
  },
  textNormal2: {
    fontFamily: 'arial',
    fontWeight: 'thin',
    fontSize: 11,
    color:'rgb(51,51,51)'
  },
  textSmall: {
    fontFamily: 'arial',
    fontWeight: 'thin',
    fontSize: 9,
    color:'rgb(51,51,51)'

  },
  textSmall2: {
    fontFamily: 'arial',
    fontWeight: 'thin',
    fontSize: 8,
    color:'rgb(112,112,112)'
  },
  textWhite: {
    fontFamily: 'arialmtbold',
    fontSize: 9,
    color: 'white'
  },
  boldText: {
    fontFamily: 'arialmtbold',
    fontWeight: 'demibold',
    fontSize: 12,
    color:'rgb(51,51,51)'
  },
  boldTextLarge: {
    fontFamily: 'arialmtbold',
    fontWeight: 'demibold',
    fontSize: 16,
    color:'rgb(51,51,51)'
  },
  boldTextSmall: {
    fontFamily: 'arialmtbold',
    fontWeight: 'demibold',
    fontSize: 9,
    color:'rgb(51,51,51)'
  },
  smallTextGray: {
    fontFamily: 'arialmtbold',
    fontWeight: 'thin',
    color: 'rgb(102,102,102)',
    fontSize: 10
  },
  body: {
    display: 'flex',
    flexGrow: 1
  },
  invoicePartnerDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  invoicePartnerDetails: {
    display: 'flex',
    width: '45%'
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 20,
  },
  table: {
    display: "table" as any,
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeft: 0,
    borderColor: '#3D6B86',
    marginTop: 10
  },
  tableRow: {
    margin: 'auto',
    flexDirection: "row",
    width: 'auto'
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderColor: '#3D6B86',
  },
  tableCell: {
    fontFamily:"arialmtbold",
    // margin: "auto",
    marginTop: 5,
    fontSize: 9,
    padding: 0,
    color:'rgb(51,51,51)'
  },
  tableCell2: {
    fontFamily:"arialmtbold",
    marginBottom: 5,
    fontSize: 9,
    padding: 0,
    color:'rgb(51,51,51)'
  },
  rowCell: {
    fontFamily:"arial",
    marginTop: 5,
    fontSize: 9,
    padding: 0,
    color:'rgb(51,51,51)'
  },
});

interface Props {
  invoiceData: any;
  logo?: string;
  user?: any;
}

const InvoiceAvizePdfTemplate = ({invoiceData, logo, user}: Props) => {

  const clientBankAccount = invoiceData?.invoice?.client?.BankAccounts?.find((bankAccount: any) =>
    bankAccount.currency === invoiceData?.invoice?.currency
  );

  const currency = JSON.parse(localStorage.getItem('eurRate') || '')?.rate;

  function formatCurrency(number: any) {
      const numberConverted = Number(number);
      if(!isNaN(numberConverted) ){
        return numberConverted.toLocaleString("en-en", {maximumFractionDigits: 2, minimumFractionDigits: 2})
            .replace(","," ");
      }else{
        return number;
      }
  }

  function formatDate(date:any){
    return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" +
        date.getFullYear();
  }
  return (
    <Document>
      <Page size="A4" wrap style={styles.pageContainer}>
        <View style={styles.container}>

          {/*Invoice header*/}
          <View style={styles.header}>

            <View style={{width: '55%'}}>
              <View style={styles.logoContainer}>
                {/* <Image src={logo} style={{width: 'auto', height: 'auto'}}/> */}
              </View>
            </View>

            <View style={{display: 'flex', width: '45%'}}>
              <View style={{display: 'flex', flexDirection: 'row', width:'100%'}}>
                <View style={styles.columnText}>
                  <Text style={styles.boldTitleText}>Aviz</Text>
                    <Text style={{marginTop:5,...styles.textLabel}}>Data emiterii: <Text style={styles.textNormal}>{formatDate(new Date(invoiceData?.invoice?.created_at_utc))}</Text></Text>
                </View>
                <View style={{width: '100%', alignItems:'flex-end'}}>
                  <Text style={styles.boldSubtitleText}>{invoiceData?.invoice?.series}{invoiceData?.invoice?.number}</Text>
                  <Text style={{marginTop:-2,...styles.textLabel}}>Cota TVA: {invoiceData?.productList?.[0]?.vat}%</Text>
                </View>
              </View>

              <View style={{
                paddingTop: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 43,
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#3d6b86',
                justifyContent: 'space-between'
              }}>
                <Text style={{marginTop: -3,
                  ...styles.boldTextWhite}}>TOTAL PLATA</Text>

                <View>
                  <Text style={{marginTop:-5,...styles.boldTextWhiteCurrency}}>
                    {formatCurrency(invoiceData?.invoice?.total_price_incl_vat)} <Text style={styles.boldTextWhite}>{invoiceData?.invoice?.currency}</Text>
                    {/*{`${invoiceData?.invoice?.total_price_incl_vat} ${invoiceData?.invoice?.currency}`}*/}
                  </Text>
                  {invoiceData?.invoice?.currency === 'EUR' &&
                      <Text style={{alignSelf:'flex-end',marginTop:-2,...styles.textWhite}}>
                        {formatCurrency(parseFloat(
                          (Number(invoiceData?.invoice?.total_price_incl_vat)
                            * Number(currency)).toFixed(2)))} Lei
                      </Text>
                  }
                </View>
              </View>
            </View>

          </View>

          {/*Invoice body*/}
          <View style={styles.body}>

            <View style={styles.invoicePartnerDetailsContainer}>

              <View style={{width: '54%', display: 'flex', paddingRight: 5}}>
                <Text style={styles.smallTextGray}>Furnizor</Text>
                <Svg height="5" width="125">
                  <Line
                      x1="0"
                      y1="2"
                      x2="200"
                      y2="2"
                      strokeWidth={0.5}
                      stroke="#4E7890"
                  />
                </Svg>
                <Text style={styles.boldSubtitleText2}>
                  {invoiceData?.invoice?.client?.name}
                </Text>
                <Text style={styles.textLabel}>
                  CIF: <Text style={styles.textNormal}>{invoiceData?.invoice?.client?.unique_identification_number}</Text>
                </Text>
                <Text style={styles.textLabel}>
                  Reg. com.:<Text style={styles.textNormal}> {invoiceData?.invoice?.client?.trade_register_registration_number}</Text>
                </Text>
                <Text style={{marginTop:4, marginBottom:4,...styles.textLabel}}>
                  Adresa: {invoiceData?.invoice?.client?.address}
                </Text>
                {!!clientBankAccount &&
                    <Fragment>
                        <Text style={styles.textLabel}>
                            IBAN({clientBankAccount.currency}){clientBankAccount.iban}
                        </Text>
                        <Text style={styles.textLabel}>
                            Banca: {clientBankAccount.bank_name}
                        </Text>
                    </Fragment>
                }
              </View>

              <View style={{width: '45%', display: 'flex', paddingLeft: 5}}>
                <Text style={styles.smallTextGray}>Client</Text>
                <Svg height="5" width="125">
                  <Line
                      x1="0"
                      y1="2"
                      x2="200"
                      y2="2"
                      strokeWidth={0.5}
                      stroke="#4E7890"
                  />
                </Svg>
                <Text style={styles.boldSubtitleText2}>
                  {invoiceData?.invoice?.buyer?.name}
                </Text>
                <Text style={styles.textLabel}>
                  CIF: <Text style={styles.textNormal}>{invoiceData?.invoice?.buyer?.unique_identification_number}</Text>
                </Text>
                <Text style={styles.textLabel}>
                  Reg. com.:<Text style={styles.textNormal}> {invoiceData?.invoice?.buyer?.trade_register_registration_number}</Text>
                </Text>
                <Text style={{marginTop:4, marginBottom:4,...styles.textLabel}}>
                  Adresa: {invoiceData?.invoice?.buyer?.address}
                </Text>
              </View>
            </View>

            {/*Table*/}
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{
                  width: "4%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"right"
                }}><Text style={styles.tableCell}>Nr. crt</Text></View>
                <View style={{
                  width: "40%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"left",
                  justifyContent:'center',
                  paddingLeft: 10,
                }}><Text style={styles.tableCell}>Denumirea produselor sau a serviciilor</Text></View>
                <View style={{
                  width: "9%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"right",
                  justifyContent:'center'
                }}><Text style={styles.tableCell}>U.M.</Text></View>
                <View style={{
                  width: "9%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"right",
                  justifyContent:'center'

                }}><Text style={styles.tableCell}>Cant.</Text></View>
                <View style={{
                  width: "14%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"right"
                }}>

                  <Text style={styles.tableCell}>Pret unitar </Text>
                  <Text style={styles.tableCell2}>(fara TVA) -{invoiceData?.invoice?.currency}-</Text>


                </View>
                <View style={{
                  width: "10%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"right"
                }}><Text style={styles.tableCell}>Valoarea </Text>
                  <Text style={styles.tableCell2}>-{invoiceData?.invoice?.currency}-</Text>

                </View>
                <View style={{
                  width: "14%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: '#3D6B86',
                  textAlign:"right"
                }}><Text style={styles.tableCell}>Valoarea TVA</Text>
                  <Text style={styles.tableCell2}>-{invoiceData?.invoice?.currency}-</Text>

                </View>
                {/*<View style={styles.tableCol}><Text style={styles.tableCell}>Pret unitar (fara TVA) -{invoiceData?.invoice?.currency}-</Text></View>*/}
                {/*<View style={styles.tableCol}><Text style={styles.tableCell}>Valoarea TVA -{invoiceData?.invoice?.currency}-</Text></View>*/}
              </View>
              {invoiceData?.productList?.map((product: ProductDto, index: number) => {
                return (
                  <View key={product.product_id} style={styles.tableRow}>
                    <View style={{
                      width: "4%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"right",
                      justifyContent:'center'
                    }}><Text style={styles.rowCell}>{index + 1}</Text></View>
                    <View style={{
                      width: "40%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"left",
                      justifyContent:'center',
                      paddingLeft: 10,
                    }}><Text style={styles.rowCell}>{product.product_name}</Text></View>
                    <View style={{
                      width: "9%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"right",
                      justifyContent:'center'
                    }}><Text
                      style={styles.rowCell}>{product.unit_of_measure}</Text></View>
                    <View style={{
                      width: "9%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"right",
                      justifyContent:'center'
                    }}><Text style={styles.rowCell}>{product.quantity}</Text></View>
                    <View style={{
                      width: "14%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"right",
                      justifyContent:'center'
                    }}><Text style={styles.rowCell}>{formatCurrency(product.purchase_price)}</Text></View>
                    <View style={{
                      width: "10%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"right",
                      justifyContent:'center'
                    }}><Text style={styles.rowCell}>{formatCurrency(product.quantity * product.purchase_price)}</Text></View>
                    <View style={{
                      width: "14%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeft: 0,
                      borderRight: 0,
                      borderTop: 0,
                      borderColor: '#999999',
                      textAlign:"right",
                      justifyContent:'center'
                    }}><Text style={styles.rowCell}>{
                      formatCurrency(parseFloat((calculatePercentage(Number(product.purchase_price), Number(product.vat))
                          - Number(product.purchase_price)).toFixed(2)))
                    }</Text></View>
                  </View>
                );
              })}
            </View>

            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 0}}>
              <View style={{display: 'flex', marginTop:15}}>
                <Text style={styles.textLabel}>Curs <Text style={styles.textNormal}>1 EUR = {currency} Lei</Text></Text>
                <Text style={{marginTop:5,...styles.textLabel}}>Intocmit de: {user?.first_name} {user?.last_name}</Text>
                { user?.id_card_number &&<Text style={{marginTop:5,...styles.textLabel}}>B.I/C.I: {user?.id_card_series} {user?.id_card_number}</Text>}
              </View>

              <View style={{display: 'flex', flexDirection: 'row', backgroundColor: '#f7f7f7', width: '45%'
                , paddingTop:13, paddingLeft: 10, paddingBottom: 10}}>
                <Text style={styles.textNormal2}>Total</Text>
                <View style={{display: 'flex', marginLeft: 40, alignItems:'flex-end'}}>
                  <Text style={styles.textNormal2}>
                    {formatCurrency(invoiceData?.invoice?.total_price)} {invoiceData?.invoice?.currency}
                  </Text>
                  {invoiceData?.invoice?.currency === 'EUR' &&
                      <Text style={styles.textSmall}>
                        {formatCurrency(parseFloat(
                          (Number(invoiceData?.invoice?.total_price)
                            * Number(currency)).toFixed(2)))} Lei
                      </Text>
                  }
                </View>
                <View style={{display: 'flex', marginLeft: 40, alignItems:'flex-end'}}>
                  <Text style={styles.textNormal2}>
                    {invoiceData?.invoice?.total_vat} {invoiceData?.invoice?.currency}
                  </Text>
                  {invoiceData?.invoice?.currency === 'EUR' &&
                      <Text style={styles.textSmall}>
                        {formatCurrency(parseFloat(
                          (Number(invoiceData?.invoice?.total_vat)
                            * Number(currency)).toFixed(2)))} Lei
                      </Text>
                  }
                </View>
              </View>
            </View>

            <View
              style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 15}}>
              <View style={{width: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={{
                  fontFamily: 'arial',
                  fontSize: 10.5,
                  color: '#3d6b86'
                }}>TOTAL PLATA</Text>
                <View style={{display: 'flex', marginLeft: 20 , alignItems:'flex-end',marginTop: -5}}>
                  <Text style={styles.boldTextLarge}>
                    {formatCurrency(invoiceData?.invoice?.total_price_incl_vat)} <Text style={styles.boldText}>{invoiceData?.invoice?.currency}</Text>
                  </Text>
                  {invoiceData?.invoice?.currency === 'EUR' &&
                      <Text style={styles.boldTextSmall}>
                        {formatCurrency(parseFloat(
                          (Number(invoiceData?.invoice?.total_price_incl_vat)
                            * Number(currency)).toFixed(2)))} Lei
                      </Text>
                  }
                </View>
              </View>
            </View>

          </View>

        </View>

        {/*Footer*/}
        <View fixed>
          <View style={{
            display: 'flex',
            paddingVertical: 5,
            paddingHorizontal: 5,
            width: '100%',
            backgroundColor: '#f7f7f7',
          }}>
            <Text style={{
              fontFamily: 'Roboto-Bold',
              fontWeight: 'bold',
              fontSize: 9.5,
              color: 'rgb(51,51,51)',
              marginBottom: 5
            }}>{invoiceData?.invoice?.client?.name}
            </Text>
            <Text style={{marginBottom:5,...styles.textLabel}}>
              Tel. {user?.phone}; Email: {user?.email};
            </Text>   <Text style={styles.textLabel}>
              IBAN({clientBankAccount?.currency}) {clientBankAccount?.iban} ; Banca: {clientBankAccount?.bank_name}
            </Text>
          </View>
          <View style={{marginLeft:5, marginTop:5}}>
            <Text style={styles.textSmall2}>Factura este valabila fara semnatura si stampila, conform art. 319 alin. 29
              din legea 227/2015.</Text>
          </View>
        </View>


      </Page>
    </Document>
  )
}

export default InvoiceAvizePdfTemplate;
