import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import * as api from "../../api/api-client";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Table from "../../components/table/Table";
import { InvoiceProductTableHeaders } from "./InvoiceProductTableConstants";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { InvoiceProductInfo } from "../../types/InvoiceDto";
import { Close } from "@mui/icons-material";
import AddInvoiceProductForm from "../../components/forms/AddInvoiceProductForm";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";
import EditInvoiceProductForm from "../../components/forms/UpdateInvoiceProductForm";
import SelectFormControl from "../../components/form-helpers/SelectFormControl";

const NoticeDetailsPage = () => {
  const [invoice, setInvoice] = useState<any>({} as any);
  const [productList, setProductList] = useState<any>([]);
  const [series, setSeries] = useState<any[]>([{ label: 'Selecteaza o serie', value: 'no' }]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addActionDialogOpen, setAddActionDialogOpen] = useState<boolean>(false);
  const [resetInvoice, setResetInvoice] = useState<number>(0);
  const [editInvoiceProductDialogOpen, setEditInvoiceProductDialogOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({} as any);
  const [addresses, setAddresses] = useState<any>([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      status: 'default',
      sent_status: 'default',
      series: '',
      number: '',
      created_at_utc: '',
      deadline_at: '',
      drop_off_address_id: '',
      pickup_address_id: '',
      driver_info: ''
    }
  });

  const { id } = useParams();

  const actions = [
    {
      name: "removeInvoiceItem",
      displayName: "Remove product",
      action: removeInvoiceItem
    },
    {
      name: "updateInvoiceItem",
      displayName: "Update product",
      action: updateInvoiceItem
    }
  ]

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    api.get('/users/series').then((res) => {
      const orderSeries = res.filter((resItem: any) => resItem.invoice_type === invoice.type);
      setSeries(orderSeries.map((orderSerie: any) => {
        return { label: orderSerie.series, value: orderSerie.series }
      }));
    }).catch((err) => {
      toast.error(err);
    });
  }, [invoice]);

  useEffect(() => {
    api.get(`/invoices/${id}`).then((res) => {
      setLoading(true);

      const invoiceInformation = { ...res?.invoice };
      reset({ ...res?.invoice });

      setInvoice(invoiceInformation);
      setProductList(res.productList);
    }).catch((err) => {
      toast.error(err);
    }).finally(() => {
      setLoading(false);
    })
  }, [resetInvoice]);

  const toggleEdit = () => {
    setEditMode(!editMode);
  }

  const onInvoiceDataSubmit = (data: FieldValues) => {
    data.sent_status = data.sent_status !== 'default' ? data.sent_status : null;
    data.status = data.status !== 'default' ? data.status : null;
    data.invoice_id = invoice.invoice_id;

    api.put(`/invoices`, data).then((res) => {
      toast.success('Invoice successfully updated')
      setEditMode(false);
    }).catch((err) => {
      toast.error(err?.message);
      console.error(err);
    });
  }

  const openAddInvoiceProductDialog = () => {
    setAddActionDialogOpen(true);
  }

  const onAddressAutocompleteInput = (event: any) => {
    const { value } = event.target;

    if (value.length >= 3) {
      api
        .get(`/partners/address/autocomplete?searchKey=${value}`)
        .then((res) => {
          setAddresses(res);
        })
        .catch((err) => {
          toast(err, { type: "error" });
          console.error(err);
        });
    }
  };

  const onPickupAutocompleteChange = (event: any, value: any) => {
    if (value?.address_id) setValue('pickup_address_id', value.address_id);
  }

  const onDropoffAutocompleteChange = (event: any, value: any) => {
    if (value?.address_id) setValue('drop_off_address_id', value.address_id);
  }


  const handleIssuedInvoiceProduct = (data: any) => {
    if (data.type === 'service') {
      api.post('/products', data).then((res) => {
        const productInfo: InvoiceProductInfo = {
          invoice_id: Number(id),
          product_id: res.product_id,
          quantity: data.quantity,
          unit_of_measure: data.unit_of_measure,
          purchase_price: Number(data.purchase_price)
        }

        api.post(`/invoices/${id}/product/add`, productInfo).then((res) => {
          toast.success('Invoice product successfully added');
          setResetInvoice((prevState: number) => prevState + 1);
        }).catch((err) => {
          console.error(err);
        });
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        setAddActionDialogOpen(false);

      }).catch((err) => {
        toast.error(err);
      })

      return;
    } else {
      api.post('/products/quantity/reserve', { product_name: data.product_name, quantity: data.quantity }).then((res) => {
        const productInfo: InvoiceProductInfo = {
          invoice_id: Number(id),
          product_id: data.product_id,
          quantity: data.quantity,
          purchase_price: Number(data.purchase_price),
          unit_of_measure: data.unit_of_measure
        }

        api.post(`/invoices/${id}/product/add`, productInfo).then((res) => {
          toast.success('Invoice product successfully added');
          setResetInvoice((prevState: number) => prevState + 1);
        }).catch((err) => {
          console.error(err);
        });
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        setAddActionDialogOpen(false);
      })
    }
  }

  const onInvoiceProductSubmit = (data: any) => {
    handleIssuedInvoiceProduct(data);
  }

  function removeInvoiceItem(currentRow: any) {
    const productInfo: InvoiceProductInfo = {
      invoice_id: Number(id),
      product_id: currentRow.product_id,
      quantity: currentRow.quantity,
      purchase_price: Number(currentRow.purchase_price),
      unit_of_measure: currentRow.unit_of_measure
    }

    api.post(`/invoices/${id}/product/remove`, productInfo).then((res) => {
      toast.success(res.message);
      setResetInvoice((prevState: number) => prevState + 1);
    }).catch((err) => {
      toast.error(err);
    })
  }

  function updateInvoiceItem(currentRow: any) {
    setSelectedProduct(currentRow);
    setEditInvoiceProductDialogOpen(true);
  }

  const onInvoiceProductEdit = (data: FieldValues) => {
    data.invoice_id = invoice.invoice_id;
    data.invoice_product_id = productList.find((prod: any) => prod.product_name === data.product_name).invoice_product_id;

    api.post(`/invoices/${invoice.invoice_id}/product/update`, data).then((res) => {
      setResetInvoice((prevState: number) => prevState + 1);
      toast.success('Product succesffully updated');
    }).catch((err) => {
      console.error(err)
    }).finally(() => {
      setEditInvoiceProductDialogOpen(false);
    });
  }

  return (
    <Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
      <Toolbar sx={{ width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important' }}>
        <Card sx={{ width: '100%' }}>
          <Button onClick={toggleEdit}>Editare</Button>
        </Card>
      </Toolbar>
      <Box>
        <>
          {!loading &&
            <form onSubmit={handleSubmit(onInvoiceDataSubmit)}>

              {invoice && !!(Object.keys(invoice).length > 0) && <Box sx={{
                display: "flex",
                width: '100%',
                flexDirection: { xs: 'column', md: 'row' }
              }}>

                <AutocompleteFormControl
                  control={control}
                  name="pickup_address_id"
                  displayText="Adresa preluare"
                  defaultValue={invoice.pickup_address}
                  options={addresses}
                  optionLabelFunc={(addresses) => addresses?.address_id ? (addresses?.nickname || "") : ""}
                  rules={{
                    required: { value: true, message: "Adresa este obligatorie." },
                  }}
                  onInputHandler={onAddressAutocompleteInput}
                  onChangeHandler={onPickupAutocompleteChange}
                  variant="outlined"
                  disabled={!editMode}
                />
                <AutocompleteFormControl
                  control={control}
                  defaultValue={invoice.drop_off_address}
                  name="drop_off_address_id"
                  displayText="Adresa livrare"
                  options={addresses}
                  optionLabelFunc={(addresses) => addresses?.address_id ? (addresses?.nickname || "") : ""}
                  rules={{
                    required: { value: true, message: "Adresa este obligatorie." },
                  }}
                  onInputHandler={onAddressAutocompleteInput}
                  onChangeHandler={onDropoffAutocompleteChange}
                  variant="outlined"
                  disabled={!editMode}
                />

              </Box>}

              <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>

                {invoice?.type === "received" ?
                  <BasicFormControl control={control} name="series" displayText="Series" disabled={!editMode} />
                  :
                  <SelectFormControl control={control}
                    name='series'
                    selectOptions={series}
                    displayText='Serie'
                    rules={{ required: { value: true, message: 'Seria este obligatorie' } }}
                    disabled={!editMode}
                    errors={errors}
                  />}
                <BasicFormControl control={control} name="number" displayText="Number" disabled={!editMode} />

              </Box>

              <Box sx={{ display: 'flex' }}>

                <DatePickerFormControl control={control} name="created_at_utc" displayText="Created at"
                  rules={{ required: { value: true, message: 'Creation date is required' } }}
                  errors={errors} disabled={!editMode} />

              </Box>

              <Box sx={{ display: 'flex' }}>
                <BasicFormControl control={control} name="driver_info" displayText="Informatii sofer"
                  errors={errors} disabled={!editMode} />
              </Box>

              {editMode &&
                <Button type="submit" variant='contained' sx={{ width: '100%' }}>Submit invoice data</Button>
              }
            </form>
          }
          <Table headers={InvoiceProductTableHeaders}
            rows={productList}
            actions={actions}
            handleAddActionClick={openAddInvoiceProductDialog}
          />
        </>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={addActionDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title"
          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          Add invoice products
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setAddActionDialogOpen(false)}
            aria-label="close"
            sx={{ justifySelf: 'end' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%' }}>
          <AddInvoiceProductForm handleAddInvoiceProduct={onInvoiceProductSubmit} invoiceType={invoice.type} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={editInvoiceProductDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-2"
          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          Update invoice products
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setEditInvoiceProductDialogOpen(false)}
            aria-label="close"
            sx={{ justifySelf: 'end' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%' }}>
          <EditInvoiceProductForm product={selectedProduct} editInvoiceProductForm={onInvoiceProductEdit}
            invoiceType={invoice.type} />
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default NoticeDetailsPage;

