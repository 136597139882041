import {Box, Container} from "@mui/material";
import CreateEmployeeForm from "../../components/forms/CreateEmployeeForm";
import {CreateEmployee} from "../../types/EmployeeDto";
import {toast} from "react-toastify";
import * as api from "../../api/api-client";
import {useNavigate} from "react-router-dom";

const CreateEmployeePage = () => {
  const navigate = useNavigate();

  const onEmployeeInput = (data: CreateEmployee) => {
    api.post('/employees', data).then((res: any) => {
      toast.success('Employee successfully created!');
      navigate('/employees');
    }).catch((err) => {
      console.log(err);
      toast.error(err.message);
    })
  }

  return (
    <Container sx={{maxWidth: '100vw !important', height: '100%', m: 0, mt: 4}}>
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <h2>Create employee</h2>
      </Box>
      <CreateEmployeeForm onEmployeeInput={onEmployeeInput}/>
    </Container>
  )
}

export default CreateEmployeePage;