import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";
import {Box, Button} from "@mui/material";
import {useEffect, useState} from "react";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";

interface Props {
  partner_id: number;
  existingAddress?: any;
  onAddressSubmit: (data: FieldValues) => void;
}

const CreateAddressForm = (props: Props) => {
  const [addresses, setAddresses] = useState<any>([]);

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      county: 'Bihor',
      country: 'Romania',
      city: 'Oradea',
      address_point: '',
      nickname: '',
      address_id: ''
    }
  });

  useEffect(() => {
    if (props.existingAddress) {
      props.existingAddress.address_point = props.existingAddress.address;

      reset(props.existingAddress);
    }
  }, [props.existingAddress])

  const handleAddressSubmit = (data: FieldValues) => {
    props.onAddressSubmit(data);
  }

  const onAddressInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/address/autocomplete?searchKey=${value}`).then((res) => {

        if (res.length === 0) {
          setAddresses([{nickname: value}])
        } else {
          setAddresses(res);
        }

      }).catch((err) => {
        toast.error(err);
      });
    }
  }

  const onAddressBlur = (event: any) => {
    const {name, value} = event.target;

    if (addresses.length <= 0) {
      setValue(name, {nickname: value});
    }
  }

  const onAddressChange = (event: any, value: any) => {
    setValue('nickname', value?.nickname);
    if (value?.address_id) setValue('address_id', value.address_id);
  }

  return (
    <form onSubmit={handleSubmit(handleAddressSubmit)}>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '95%'}}>
          {/*<BasicFormControl control={control} name='nickname' displayText='Nickname' variant='outlined' errors={errors}*/}
          {/*                  rules={{*/}
          {/*                    required: {value: true, message: 'Nickname este obligatoriu.'}*/}
          {/*                  }}/>*/}
          <AutocompleteFormControl control={control}
                                   name='nickname'
                                   displayText='Nickname'
                                   options={addresses}
                                   optionLabelFunc={(addresses) => addresses?.nickname || ''}
                                   onInputHandler={onAddressInput}
                                   onChangeHandler={onAddressChange}
                                   onBlurHandler={onAddressBlur}
                                   rules={{
                                     required: {value: true, message: 'Campul este obligatoriu.'}
                                   }}
                                   errors={errors}
          />

          <BasicFormControl control={control} name='county' displayText='Judet' variant='outlined' errors={errors}
                            rules={{
                              required: {value: true, message: 'Judetul este obligaoriu.'}
                            }}/>
          <BasicFormControl control={control} name='country' displayText='Tara' variant='outlined' errors={errors}
                            rules={{
                              required: {value: true, message: 'Tara este obligatorie.'}
                            }}/>
          <BasicFormControl control={control} name='city' displayText='Oras' variant='outlined' errors={errors}
                            rules={{
                              required: {value: true, message: 'Orasul este obligatoriu.'}
                            }}/>
          <BasicFormControl control={control} name='address_point' displayText='Addresa' variant='outlined'
                            errors={errors}
                            rules={{
                              required: {value: true, message: 'Adresa este obligatorie.'}
                            }}/>
        </Box>
        <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
      </Box>
    </form>
  )
}

export default CreateAddressForm;