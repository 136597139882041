import React, {useEffect, useState} from "react";
import {
    Box,
    Container,
    Toolbar
} from "@mui/material";
import {useForm} from "react-hook-form";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import {useParams} from "react-router-dom";

import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";

const ReceiptDetailsPage = () => {
    const [loading, setLoading] = useState(true);

    const {
        control,
        reset
    } = useForm({
        defaultValues: {
            series: "",
            series_number: "",
            buyer_partner: "",
            seller_partner: "",
            total_price: "",
            vat_price: "",
            currency: "",
            cash_register: "",
            issued_date: ""
        }
    });

    const {id} = useParams();

    useEffect(() => {
        api.get(`/receipt/${id}`).then((res: any) => {
            reset({
                series: res?.series,
                series_number: res?.series_number,
                buyer_partner: res?.buyer_partner.name,
                seller_partner: res?.seller_partner.name,
                total_price: res?.total_price,
                vat_price: res?.vat_price,
                currency: res?.currency,
                cash_register: res?.cash_register.name,
                issued_date: res?.issued_date
            })

            setLoading(false);
        }).catch((err) => {
            toast.error(err);
        });
    }, []);

    return (
        <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
            <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>

            </Toolbar>
            {!loading && <Box>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                    <BasicFormControl control={control} disabled displayText="Serie" name="series" />
                    <BasicFormControl control={control} disabled displayText="Numar" name="series_number" />
                    <DatePickerFormControl control={control} disabled displayText="Data emiterii" name="issued_number" />
                </Box>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                    <BasicFormControl control={control} disabled displayText="Cumparator" name="buyer_partner" />
                    <BasicFormControl control={control} disabled displayText="Vanzator" name="seller_partner" />
                </Box>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                    <BasicFormControl control={control} disabled displayText="Pret total" name="total_price" />
                    <BasicFormControl control={control} disabled displayText="Din care TVA" name="vat_price" />
                    <BasicFormControl control={control} disabled displayText="Valuta" name="currency" />
                    <BasicFormControl control={control} disabled displayText="Casa" name="cash_register" />
                </Box>
            </Box>}
        </Container>
    )
}

export default ReceiptDetailsPage;