import {Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Close} from "@mui/icons-material";

interface Props {
  isDialogOpen: boolean;
  dialogTitle: string;
  handleDialogState: (dialogState: boolean) => void;
  children: JSX.Element;
  textSize?: string;
}

const DialogContainer = (props: Props) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return <>
    <Dialog
      fullScreen={fullScreen}
      open={props.isDialogOpen}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title"
                   sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h4'>{props.dialogTitle}</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => props.handleDialogState(false)}
          aria-label="close"
          sx={{justifySelf: 'end'}}
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{height: '100%'}}>
        {props.children}
      </DialogContent>
    </Dialog>
  </>
}

export default DialogContainer;

