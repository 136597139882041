export const UserSettingsTableConstants = [
  {
    id: "invoice_type",
    label: "Tip serie",
    width: 100
  },
  {
    id: "series",
    label: "Serie",
    width: 100
  },
  {
    id: "default",
    label: "Serie implicita",
    width: 100
  }
]

export const UserEmailTableConstants = [
  {
    id: "partner_name",
    label: "Firma",
    width: 100
  },
  {
    id: "partner_email",
    label: "Email",
    width: 100
  },
  {
    id: "smtp",
    label: "SMTP",
    width: 100
  }
]