import { Box } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import Button from '../Button';
import { ProductDto } from '../../types/ProductDto';
import { useEffect, useState } from 'react';
import * as api from '../../api/api-client';
import SelectFormControl from '../form-helpers/SelectFormControl';
import BasicFormControl from '../form-helpers/BasicFormControl';
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import {toast} from "react-toastify";

interface CreateProductDto {
  quantity: number;
  purchase_price: number;
  vat: number;
  product_name: string;
  nc8_code_id:number,
  nc8Code: any
}

interface Props {
  onProductInput: (product: CreateProductDto) => void;
  product?: ProductDto;
  disableNameInput?: boolean;
  resetTrigger?: number;
  invoiceType?: 'proforma' | 'issued' | 'received';
  handleQuantityChange?: (quantity: number) => void;
}

const CreateProductForm = (props: Props) => {
  const [product, setProduct] = useState<ProductDto | undefined>(props.product);

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    setError,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      type: 'select type',
      quantity: 0,
      purchase_price: 0,
      unit_of_measure: '',
      material: '',
      product_name: '',
      vat: 19,
      nc8_code_id: null
    },
  });

  const watchQuantity = watch('quantity');
  const [nc8Codes, setNc8Codes] = useState<any>([]);

  useEffect(() => {
    setProduct(props.product);
    reset(props.product);
  }, [props.product]);

  useEffect(() => {
    reset({
      type: 'select type',
      quantity: Number(''),
      purchase_price: 0,
      unit_of_measure: '',
      material: '',
      product_name: '',
      vat: 19,
      nc8_code_id: null
    });
  }, [props.resetTrigger]);

  useEffect(() => {
    if (watchQuantity) {
      if (!props.invoiceType || props.invoiceType === 'received') return;

      if (getValues('type') === 'service') return;

      setTimeout(() => {
        api
          .post('/products/quantity/check', {
            product_name: getValues('product_name'),
            quantity: getValues('quantity'),
          })
          .then((res) => {
            clearErrors('quantity');
          })
          .catch((err: any) => {
            console.error(err);
            setError('quantity', { message: 'Insufficient quantity' });
          });
      }, 1000);
    }
  }, [watchQuantity]);

  const onProductInput = (data: FieldValues) => {
    props.onProductInput(data as CreateProductDto);
  };

  const onNc8CodeAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/products/nc8codes/autocomplete?searchKey=${value}`).then((res) => {
        setNc8Codes(res);
      }).catch((err) => {
            toast(err, {type: 'error'});
            console.error(err);
          }
      )
    }
  }

  const onNc8AutocompleteChange = (event: any, value: any) => {
    setValue('nc8_code_id', value?.nc8_code_id || null);
  }

  return (
    <form onSubmit={handleSubmit(onProductInput)}>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <SelectFormControl
            control={control}
            name="type"
            selectOptions={[
              { label: 'Goods', value: 'goods' },
              { label: 'Service', value: 'service' },
            ]}
            rules={{
              required: { value: true, message: 'Tipul produsului este obligatoriu' },
              pattern: { value: /goods|service/, message: 'Tip produs incorect' },
            }}
            errors={errors}
          />

          <BasicFormControl control={control} name="unit_of_measure" displayText="Unitate masura" variant="outlined" />

          <BasicFormControl control={control} name="material" displayText="Material" variant="outlined" />
        </Box>
      </Box>

      <Box sx={{ display: 'flex' }}>
        {!props.disableNameInput && (
          <BasicFormControl
            control={control}
            name="product_name"
            displayText="Nume"
            variant="outlined"
            rules={{ required: { value: true, message: 'Numele produsului este obligatoriu' } }}
            errors={errors}
          />
        )}

        <BasicFormControl
          control={control}
          name="quantity"
          displayText="Cantitate"
          variant="outlined"
          errors={errors}
          rules={{
            required: { value: true, message: 'Cantitatea este obligatorie' },
            pattern: { value: /[0-9]/, message: 'Cantitatea trebuie sa fie un numar' },
          }}
        />

        <BasicFormControl
          control={control}
          name="vat"
          displayText="VAT"
          rules={{
            required: { value: true, message: 'TVA este obligatoriu' },
            pattern: { value: /[0-9]/, message: 'TVA-ul trebuie sa fie un numar' },
          }}
          errors={errors}
          variant="outlined"
        />
      </Box>

      <Box sx={{ display: 'flex' }}>
        <BasicFormControl
          control={control}
          name="purchase_price"
          displayText="Pret achizitie (incl. TVA)"
          rules={{
            required: { value: true, message: 'Pret achizitie obligatoriu' },
            pattern: { value: /[0-9]/, message: 'Pretul trebuie sa fie un numar' },
          }}
          errors={errors}
          variant="outlined"
        />
        <AutocompleteFormControl control={control}
                                 name='nc8_code_id'
                                 displayText='Cod NC'
                                 options={nc8Codes}
                                 optionLabelFunc={(nc8Code) => (nc8Code? (nc8Code.code + ' - '+nc8Code.description) : "")}
                                 onInputHandler={onNc8CodeAutocompleteInput}
                                 onChangeHandler={onNc8AutocompleteChange}
                                 variant='outlined'
        />
        {/*<BasicFormControl control={control}*/}
        {/*                  name='purchase_price'*/}
        {/*                  displayText='Purchase price (without VAT)'*/}
        {/*                  rules={{*/}
        {/*                    required: {value: true, message: 'Purchase price is required'},*/}
        {/*                    pattern: {value: /[0-9]/, message: 'Purchase price must be a number'}*/}
        {/*                  }}*/}
        {/*                  errors={errors}*/}
        {/*                  variant='outlined'*/}
        {/*/>*/}
      </Box>
      <Button color="primary" sx={{ width: '100%', mt: '1rem' }} type="submit" title="Submit" />
    </form>
  );
};

export default CreateProductForm;
