import DrauberSignature from "../../assets/Drauber.jpg";
import DrauberLogo from "../../assets/logo-Drauber.png";
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {reversePercentage} from "../../utils/price";

interface Props {
  invoiceDetails: any;
}

const styles
  = StyleSheet.create({
  borderText: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginHorizontal: 'auto',
    marginTop: 20,
    border: '5px solid gray',
    paddingVertical: 5
  },
  textNormal: {
    paddingVertical: 3,
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textNormalNoPadding: {
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textBoldNoPadding: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
  },
  borderTextBold: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
    paddingVertical: 1
  },
  logo: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', width: '27%'
  },
  flex: {
    display: 'flex'
  },
  signature: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15%',
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20
  },
  borderColumn: {
    width: '40%', border: '0.5px solid black'
  },
  borderValue: {
    width: '60%',
    border: '0.5px solid black'
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  titleText: {
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
    fontSize: 20
  },
  clientInformation: {
    flexDirection: 'column',
    width: '90%',
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 'auto'
  },
  pageContainer: {
    maxHeight: '100%',
    flexDirection: 'column',
    width: '90%',
    paddingTop: 20,
    paddingBottom: 120
  },
  centered: {},
  truckDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginVertical: 15,
    marginHorizontal: 'auto',
    flexGrow: 0.5
  },
  transportInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginLeft: 30,
    marginRight: 10,
  },
  footerSignature: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  importantText: {width: '90%', marginLeft: 30, marginVertical: 10},
  footerContainer: {width: '90%', margin: 20, flexGrow: 1}
});

const TmsTransportTemplate = (props: Props) => {
  const dateDiff = (date_from: string, date_to: string) => {
    // return new Date(date_fro)
    const parsedFrom = new Date(date_from).setHours(0, 0, 0, 0);
    const parsedTo = new Date(date_to).setHours(0, 0, 0, 0);

    return parsedFrom.valueOf() !== parsedTo.valueOf();
  }

  return (
    <Document style={{display: 'none'}}>
      <Page size='A4' wrap style={styles.pageContainer}>

        <View style={styles.header}>

          <Image style={{width: '30%', height: '100%', margin: 'auto'}} src={DrauberLogo}/>

          <View style={{marginLeft: 10, width: '50%'}}>
            <Text style={styles.textBoldNoPadding}>Drauber S.R.L.</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>CUI:RO 32005049, J5/1165/2013</Text>
            </View>
            <Text style={styles.textBoldNoPadding}>Strada Beothy Odon nr. 12, Oradea, Bihor,
              Romania</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>Tel / Fax: +40 359 170 162 </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>e-mail: office@drauber.ro</Text>
            </View>
          </View>

        </View>

        <View style={styles.title}>
          <Text style={styles.titleText}>Contract/Comanda de transport
            nr. {props.invoiceDetails?.series}-{props.invoiceDetails?.number} din {new Date(props.invoiceDetails?.created_at_utc).toLocaleDateString('ro-RO')}</Text>
        </View>

        <View style={styles.clientInformation}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>Transportator:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.name}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>CUI
                :</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.unique_identification_number}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>ADRESA:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.address}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>PERSOANA DE CONTACT:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text
                style={styles.textNormal}>{`${props?.invoiceDetails?.transporter_contact || '-'}`}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.transportInfo}>
          <Text style={{
            fontFamily: 'Roboto',
            fontSize: 12
          }}>DETALII CAMION / MARFA</Text>

          <Text style={styles.textBoldNoPadding}>Marfa : {props.invoiceDetails?.package_info}</Text>
          <Text style={styles.textBoldNoPadding}>Nr. auto
            : {props?.invoiceDetails?.car_reg_number || '-'}</Text>
          <Text style={styles.textBoldNoPadding}>Sofer / Tel.
            Sofer: {props?.invoiceDetails?.driver_info || '-'}</Text>
          <Text
            style={styles.textBoldNoPadding}>Observatii: {props?.invoiceDetails?.remarks_transporter}</Text>
        </View>

        {props?.invoiceDetails?.OrderDetails?.map((orderDetailsItem: any) => (
          <>
            {orderDetailsItem.type === 'PICKUP' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Incarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data
                                / Ora
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras/Cod/Tara
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.location}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks || '-'}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }

            {orderDetailsItem.type === 'DROPOFF' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Descarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data / Ora :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras/Cod/Tara :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.location}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }
          </>
        ))}

        <View>
          <View style={styles.importantText}>
            <Text style={{
              fontFamily: 'Times-Bold',
              fontSize: '16'
            }}>Pret
              convenit: {parseFloat((Number(props.invoiceDetails?.transporter_price).toFixed(2)))}
              {' ' + props.invoiceDetails.transporter_currency}
              {props.invoiceDetails?.transporter_vat > 0 ? ' + TVA' : ''} </Text>
          </View>

        </View>

        <View wrap break={props.invoiceDetails?.OrderDetails.length < 3}
              style={{flexGrow: 1, width: '90%', marginLeft: 20, marginTop: 20}}>
          <Text style={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            textDecoration: 'underline',
          }}>CONDIȚII GENERALE ALE PREZENTULUI CONTRACT: </Text>

          <View style={{marginLeft: 30, marginTop: 10}}>
            <Text style={styles.borderTextBold}>1. Transportul se execută cu scrisoare de trăsură CMR, Document T sau Carnet TIR, factură comercială, certificate de 
             conformitate, certificate de calitate, certificate fitosanitare, EUR1, precum și alte documente necesare transportului, toate în
             original
           </Text>

            <Text style={styles.borderTextBold}>2. Transportatorul răspunde de consecințele pierderii și/sau lipsa documentelor mai sus menționate. </Text>

            <Text style={styles.borderTextBold}>3. Transportatorul va avea obligatoriu asigurare CMR valabilă, care să acopere valoarea mărfii transportate la momentul efectuării
            transportului. În cazul în care se constată ulterior că asigurarea nu acoperă daunele pricinuite, transportatorul este de acord să
            răspundă cu integralitatea bunurilor sale personale până la acoperirea întregului prejudiciu cauzat.
            </Text>

            <Text style={styles.borderTextBold}>4. Se solicită camion în regim de exclusivitate. Se interzice transbordarea mărfii sau intermedierea acesteia către alt transportator 
            sau case de expediții fără acceptul în scris al S.C. DRAUBER S.R.L.
            </Text>

            <Text style={styles.borderTextBold}>5. S.C. DRAUBER S.R.L., nu este răspunzatoare de nici o depășire de greutate totală sau pe axe. 
            </Text>

            <Text style={styles.borderTextBold}>6. Șoferul este obligat să asiste la încărcarea și descărcarea mărfii, acesta fiind singurul care poate stabilii modul de încărcare și
             repartizare a mărfii pe axe, fiind în mod direct răspunzător de normele generale de siguranță și de limita maximă de încărcare. 
            </Text>

            <Text style={styles.borderTextBold}>7. Orice penalități primite de la client ca urmare a daunelor produse mărfii transportate, vor fi suportate de către transportator.
            </Text>

            <Text style={styles.borderTextBold}>8. Vehiculele ce efectuează transportul vor îndeplini toate condițiile cerute de legislația transporturilor lamomentul efectuării
            transportului, incluzând cu titlu de exemplu și fără a se limita doar la acestea la: licența de transport, licența de execuție,
            asigurarea autovehicululului și a mărfii,  revizii tehnice anuale, rovignete, verificări ITP, șoferi cu certificate profesionale valabile,
            precum și orice alte avize și autorizații care sunt necesare pentru desfășurarea transportului în Uniunea Europeană.  
           </Text>

           <Text style={styles.borderTextBold}>9. În cazul în care nu se respectă oricare din condițiile înscrise în acest contract, termenul de încărcare, termenul de livrare,
          condiții de transport, alte termene și condiții neindicate în prezenta comandă însă stipulate în Convenția CMR și/sau alte acte care
          reglementează transportul internațional de mărfuri, iar nerespectarea/încălcarea au drept consecință pierderea, deteriorarea, furtul
          total sau parțial al mărfurilor transportate și/sau alte daune/pagube, suferite de cei implicați și/sau de către terți vor fi suportate în
          totalitate de transportator, răspunderea transportatorului putând fi civilă, penală, administrativă, angajarea unei singure răspunderi
          neexcluzând posibilitatea de antrenare și a celorlalte răspunderi.
          </Text> 

          <Text style={styles.borderTextBold}>10. În celelalte cazuri de întârziere la încărcare /descărcare nejustificate, se penalizează cu 300 (trei sute) euro/zi la care se vor adăuga daunele pretinse de beneficiarul transportului. 
          </Text> 

          <Text style={styles.borderTextBold}>11. Neprezentarea de către transportator la încărcare după ce a confirmat comanda, se penalizează cu 100 % din tariful de transport la care se vor adăuga orice alte pretenții indiferent de titlu și cuantum, formulate de beneficiarul transportului.
          </Text> 

          <Text style={styles.borderTextBold}> 12. Pentru serviciile de transport efectuate cu respectarea integrala a clauzelor prezentei comenzi, transportatorul va emite o factură în EUR sau în RON la cursul valutar EUR/RON stabilit de Banca Națională a României valabil pentru ziua emiterii facturii, document pe care se va menționa  numărul și data comenzii emise de subscrisa (NR /DATA COMENZII DRAUBER). 
          </Text>

          <Text style={styles.borderTextBold}> 13. Plata contravalorii facturii de transport se va efectua în RON/EUR, cu condiția să fie respectate toate mențiunile și clauzele contractuale din paginile 1 și 2, prin transfer bancar, în 45 zile bancare de la primirea facturii originale,  în care să fie specificat numărul de comandă, precum și a următoarelor documente:
          </Text>
          <Text style={styles.borderTextBold}> - FACTURA ce se va emite cu luna expedierii documentelor, 2 (două) CMR-uri ORIGINALE confirmate la locul descărcării, LIEFERSCHEIN CONFIRMAT de la locul descărcarii cu semnătura și ștampila de primire a mărfii fără rezerve,  TERMODIAGRAMA printabilă la cursele efectuate cu dube frig,  respectiv ALTE DOCUMENTE solicitate de către client care atestă calitatea, cantitatea, periclitatea sau efectuarea cursei menționate (detalii la paragraful ”Cantitatea și Natura mărfii”). 
          </Text>

          <Text style={styles.borderTextBold}>14. Termenul de plată este afectat de condiția suspensivă a încasării integrale a contravalorii transportului de la beneficiarul final.
          </Text>
          
          <Text style={styles.borderTextBold}>15. În cazul în care nu se vor trimite actele aferente transportului (FACTURĂ, CMR+ AVIZE) în original, până în data de 15 (cincisprezece) a următoarei luni, se va decala termenul de plată la 90 zile bancare,  respectiv se va scădea din valoarea transportului 35 euro.
          </Text>
          
          <Text style={styles.borderTextBold}>16. DURATA CONTRACTULUI: Prezenta comanda se va confirma (semnatură, ștampilă) în termen de 2 ore, în caz contrar și în lipsa refuzului explicit în scris, se va considera acceptată. 
          </Text>
          
          <Text style={styles.borderTextBold}>17. Este interzis a se prezenta această comandă unei terțe părti, iar orice încercare de a contacta direct clientul nostru, depozitul de încărcare / descărcare, se consideră încălcare a neutralității și se va penaliza cu 100.000 Euro.
          </Text>

          <Text style={styles.borderTextBold}>18. Orice litigiu, indiferent de natura sa, care decurge din executarea prezentului contract sau are legătură cu acesta, în măsura în care nu va fi soluționat pe cale amiabilă, va fi de competența materială a instanțelor de judecată din localitatea Oradea, jud.Bihor.
          </Text>
          
          <Text style={styles.borderTextBold}>19. Subscrisa se obligă să respecte prevederile Regulamentului UE nr. 679/2016 cu privire la prelucrarea datelor cu caracter personal care ne sunt comunicate de către transportator.
          
</Text>
          
        </View>

         <View style={styles.footerSignature}>
            <View style={{justifyContent: 'flex-start'}}>
              <Text style={styles.textNormal}>SC DRAUBER SRL</Text>
              <Text style={styles.textNormal}>EVA JURJAC - 0740 452 773</Text>
              {/*<Image style={{width: "80%"}} src={DrauberSignature}/>*/}
            </View>
            <View style={{width: '100%', textAlign: 'right'}}>
              <Text style={styles.textNormal}>CONFIRMARE TRANSPORT OBLIGATORIU !!! </Text>
              
            </View>
          </View>
        </View>

        <Image style={{position: 'absolute', bottom: 30, marginLeft: 30, width: '16%', height: '12%'}} fixed
               src={DrauberSignature}/>
      </Page>
    </Document>


  )
}

export default TmsTransportTemplate;
