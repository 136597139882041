export const BankRegisterTableConstants = [
    {
        id: 'iban',
        label: 'IBAN',
        width: 100,
        type: 'href'
    },
    {
        id: 'balance',
        label: 'Sold',
        width: 100
    },
    {
        id: 'currency',
        label: 'Valuta',
        width: 100
    },
    {
        id: 'partner',
        label: 'Firma',
        width: 100
    }
]