import { Employee } from "../../types/EmployeeDto";
import { useEffect, useState } from "react";
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, List, TextField, Typography } from "@mui/material";
import * as api from "../../api/api-client";
import { toast } from "react-toastify";
import { FieldValues, useForm } from "react-hook-form";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";
import { useNavigate } from "react-router-dom";

const CreateTimesheetEntriesPage = () => {
  const [addresses, setAddresses] = useState([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const [selectAllCheckboxDisabled,setSelectAllCheckboxDisabled] = useState(false);
  const [filterText,setFilterText]=useState("");

  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({
    defaultValues: {
      address_id: 0,
      date: new Date(),
      observations: "",
      number_of_hours: "",
    },
  });

  useEffect(() => {
    api
      .get("/employees")
      .then((res: Employee[]) => {
        const tempEmployees: Employee[] = res.map((x: Employee) => ({ ...x, isChecked: false }));
        setEmployees(tempEmployees);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  useEffect(()=> {
    setFilteredEmployees(employees);
  },[employees])

  const submit = (data: FieldValues) => {
    const changedEmployees = employees.filter((e) => e.isChecked);

    if (changedEmployees.length === 0) {
      toast("Selecteaza cel putin un angajat.", { position: "bottom-right", type: "error" });
    } else {
      const employeesToSubmit = changedEmployees.map((x) => ({
        employee_id: x.employee_id,
        hours_worked: data.number_of_hours,
        date: data.date,
        address_id:data.address_id,
      }));

      api

        .post("/timesheet", employeesToSubmit)
        .then((res) => {
          toast.success("Timesheet successfully added");
          navigate("/hr/timesheet");
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    }
  };

  const handleChange = (opt_id: number | undefined) => (e: any, value: boolean) => {
    setFilterText("");
    setEmployees(
      employees.map((employee) => {
        if (employee.employee_id === opt_id) {
          return {
            ...employee,
            isChecked: value,
          };
        } else return employee;
      }),
    );
  };

  const handleChangeAll = (e: any, value: boolean) => {
    setEmployees(
      employees.map((i, cIndex) => {
        return {
          ...i,
          isChecked: value,
        };
      }),
    );
  };

  const onAddressAutocompleteChange = (event: any, value: any) => {
    if (value?.address_id) setValue('address_id', value.address_id);
  }

  const handleFilteredEmployees = (event:any) => {
    const {value} = event.target;
    setFilterText(value);
    if (value.length>=1){
      setFilteredEmployees(employees.filter((name)=>{
        return [name.first_name,name.last_name].join(" ").toLowerCase().includes(value.toLowerCase());
      }));
      setSelectAllCheckboxDisabled(true);
    }
    else {
      setFilteredEmployees(employees);
      setSelectAllCheckboxDisabled(false);
    }
  }

  const onAddressAutocompleteInput = (event: any) => {
    const { value } = event.target;

    if (value.length >= 3) {
      api
        .get(`/partners/address/autocomplete?searchKey=${value}`)
        .then((res) => {
          setAddresses(res);
        })
        .catch((err) => {
          toast(err, { type: "error" });
          console.error(err);
        });
    }
  };


  return (
    <form onSubmit={handleSubmit(submit)}>
      <Container sx={{ maxWidth: "100vw !important", height: "100%", m: 0, mt: 4 }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <h2>Add Timesheet Entry</h2>
        </Box>
        <Box sx={{display:'flex', width: '100%', flexDirection: {xs: 'column', md:'row'}, marginRight: '2rem'}}>
          <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
            <AutocompleteFormControl
              control={control}
              name="address_id"
              displayText="Adresa"
              options={addresses}
              optionLabelFunc={(addresses) => addresses?.address_id ? (addresses?.nickname || "") : ""}
              rules={{
                required: { value: true, message: "Adresa este obligatorie." },
              }}
              onInputHandler={onAddressAutocompleteInput}
              onChangeHandler={onAddressAutocompleteChange}
              variant="outlined"
            />

            <DatePickerFormControl
              control={control}
              name="date"
              displayText="Date"
              rules={{ required: { value: true, message: "Trebuie sa selectezi o data" } }}
              errors={errors}
              variant="outlined"
            />
            <BasicFormControl
              control={control}
              name="observations"
              displayText="Obvservatii"
              variant="outlined"
              errors={errors}
              multiline
              rows={3}
            />
            <BasicFormControl
              control={control}
              name="number_of_hours"
              displayText="Numarul de ore"
              variant="outlined"
              errors={errors}
              rules={{
                required: { value: true, message: "Trebuie sa introduci un numar de ore" },
              }}
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection:'column', width: '100%', marginLeft:'2rem', height:'100%', justifyContent: {xs: "", md: 'flex-end'}}}>
            {employees.length ? (
              <>
                <TextField value={filterText}
                onChange={handleFilteredEmployees} 
                sx={{
                  width:"100%",
                  maxWidth:360,
                  pt:2,
                }}/>
                <FormControlLabel 
                sx={{marginBottom:'1rem'}} 
                control={
                  <Checkbox 
                  onChange={handleChangeAll} 
                  disabled={selectAllCheckboxDisabled}/>
                  } 
                label="Selecteaza toti angajatii" />
                
                <List
                  sx={{
                    width: "100% !important",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 300,
                    "& ul": { padding: 0 },
                  }}
                >
                  {filteredEmployees.map((opt, index) => (
                    <Box key={opt.employee_id} sx={{display:'flex'}}>
                      <Box>
                        <Checkbox checked={opt.isChecked} onChange={handleChange(opt.employee_id)} />
                      </Box>
                      <Box>
                        <Typography fontSize={20}>{opt.first_name + " " + opt.last_name}</Typography>
                        <Typography fontSize={10}>{opt.social_security_number.slice(0, -6) + "******"}</Typography>
                      </Box>
                    </Box>
                  ))}
                </List>
              </>
            ) : (
              <Typography>Partenerul selectat nu are angajati</Typography>
            )}
          </Box>
        </Box>
        <Button sx={{ my: 2, width: "100%" }} type="submit" variant="contained">
          Submit
        </Button>
      </Container>
    </form>
  );
};

export default CreateTimesheetEntriesPage;
