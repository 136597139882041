import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
  timesheetEntry: any
  employees: any[]
  onTimesheetSubmit: any
  address:any
}

const dayTypes = [
  { label: "Zi lucratoare", value: "ZL" },
  { label: "Concediu de odihna", value: "CO" },
  { label: "Concediu de boala", value: "BO" }
]

const CreateTimesheetEntryForm = (props: Props) => {
  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      timesheet_entry_id: props.timesheetEntry.timesheet_entry_id,
      employee_id: props.timesheetEntry.employee_id,
      date: props.timesheetEntry.date,
      hours_worked: props.timesheetEntry.hours_worked,
      day_type: !isNaN(props.timesheetEntry.hours_worked) ? "ZL" : props.timesheetEntry.hours_worked,
      address_id: props.address
    }
  });

  const dayTypeWatch = watch("day_type");

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if(name === "day_type")
        setValue("hours_worked", value.day_type === "ZL" ? "0" : value?.day_type || "");
    });

    return () => { subscription.unsubscribe(); }
  }, [watch]);

  const handleTimesheetEntrySubmit = (data: FieldValues) => {
    props.onTimesheetSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(handleTimesheetEntrySubmit)}>
      <SelectFormControl control={control} name='employee_id' displayText="Angajat" variant='outlined' errors={errors} 
                        selectOptions={props.employees.map(e => ({ 
                          label: e.first_name + " " + e.last_name, 
                          value: e.employee_id 
                        }))}
                        rules={{
                          required: {value: true, message: 'Camp obligatoriu'}
                        }}/>
      <DatePickerFormControl control={control} name='date' displayText='Data' variant='outlined' errors={errors}
                        rules={{
                          required: {value: true, message: 'Camp obligatoriu'}
                        }}/>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={8} marginTop={2}>
          <SelectFormControl control={control} name='day_type' displayText="Tip Zi" variant='outlined' errors={errors}
                            selectOptions={dayTypes}
                            rules={{
                              required: {value: true, message: 'Camp obligatoriu'}
                            }}/>
        </Grid>
        <Grid item xs={4}>
          <BasicFormControl control={control} name='hours_worked' displayText='Ore lucrate' variant='outlined' errors={errors}
                            disabled={dayTypeWatch !== "ZL"}
                            rules={{
                              required: {value: true, message: 'Camp obligatoriu'}
                            }}/>
        </Grid>
      </Grid>

      <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Adauga pontaj</Button>
    </form>
  );
}

export default CreateTimesheetEntryForm;