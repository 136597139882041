import {useEffect, useState} from "react";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {Box, Card, CardContent, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";

interface Props {
  partner_id: number;
}

const PartnerComments = (props: Props) => {
  const [partnerComments, setPartnerComments] = useState<any>([]);

  useEffect(() => {
    api.get(`/partners/comments?partner_id=${props.partner_id}`).then((res: any) => {
      setPartnerComments(res);
    }).catch((err) => {
      toast.error(err.message);
    })
  }, []);

  const handleCommentDelete = (partnerComment: any) => {
    api.remove('/partners/comments', {partner_comment_id: partnerComment.partner_comment_id} as any).then((res) => {
      toast.success('Comentariul a fost sters');

      api.get(`/partners/comments?partner_id=${props.partner_id}`).then((res: any) => {
        setPartnerComments(res);
      }).catch((err) => {
        toast.error(err.message);
      })
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  return (
    <Box sx={{width: '50%', height: '400px', overflowY: 'auto'}}>
      {partnerComments.map((partnerComment: any) => (
        <Card key={partnerComment.partner_comment_id}
              sx={{
                width: {xs: '250px', md: '100%'},
                height: '140px',
                display: 'flex',
                flexDirection: 'column',
                p: '0.5rem',
                my: '1rem'
              }}>
          <CardContent>
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <Typography variant='h6'>{partnerComment.user.first_name} {partnerComment.user.last_name}</Typography>
              <Typography variant='caption'>{partnerComment.created_at_utc}</Typography>
            </Box>
            <Box sx={{my: '1rem', display: 'flex', justifyContent: 'space-between'}}>
              <Typography>{partnerComment.comment}</Typography>
              <Typography>
                <IconButton onClick={(ev) => handleCommentDelete(partnerComment)}>
                  <DeleteForever/>
                </IconButton>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}

export default PartnerComments;