import React from "react";

import { Container } from "@mui/material";
import { Box } from "@mui/system";
import Calendar from "../../components/table/Calendar";


const TimesheetDetailsPage = () => {
  return <Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
    <Box>
      <Calendar/>
    </Box>
  </Container>
}

export default TimesheetDetailsPage;