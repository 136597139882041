import { ReactNode } from 'react';
import {
    Navigate,
    useLocation,
} from 'react-router-dom';
import useAuth from '../context/AuthContext';
import {Roles} from "../common/enums/roles";
import jwtDecode from "jwt-decode";

const ProtectedRoute = ({ children, roles }: { children: ReactNode, roles:Roles[] }) => {
    const { token } = useAuth();

    if (!token) {
        return <Navigate to="/login" replace state={{ error: 'Please log in' }} />;
    }
    const role = (jwtDecode(token) as any).role;

    if(roles && roles.length>0 && !roles.includes(role)){
        return <Navigate to="/" replace state={{ error: 'Nu ai permisiunea necesara' }} />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
