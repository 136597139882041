export const ProductTableHeaders = [
  {
    id: "product_name",
    label: "Nume",
    width: 100,
    type: 'href'
  },
  {
    id: "quantity",
    label: "Cantitate",
    width: 100,
  },
  {
    id: "purchase_price",
    label: "Pret",
    width: 100,
  },
  {
    id: "type",
    label: "Tip",
    width: 100,
  },
  {
    id: "nc8Code",
    label: "Cod NC",
    width: 250,
    type: "string",
  },
  {
    id: "created_at_utc",
    label: "Creat",
    width: 250,
    type: "date",
  },
];
