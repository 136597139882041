import {Box, Button} from "@mui/material";
import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";

interface Props {
  onRecipientSubmit: (data: FieldValues) => void;
}

const AddRecipientEmailForm = (props: Props) => {
  const {
    handleSubmit,
    formState: {errors},
    control
  } = useForm();

  const handleRecipientSubmit = (data: FieldValues) => {
    props.onRecipientSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(handleRecipientSubmit)}>
      <Box sx={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <Box sx={{width: '95%'}}>
          <BasicFormControl control={control}
                            name='recipient_email'
                            displayText='Adresa destinatar'
                            errors={errors}
                            rules={{
                              required: {value: true, message: 'Adresa destinatarului este obligatorie'}
                            }}
          />
        </Box>

        <Button type='submit' variant='contained'>Submit</Button>
      </Box>
    </form>
  )
}

export default AddRecipientEmailForm;