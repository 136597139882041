import {useEffect, useState} from "react";
import * as api from "../../api/api-client";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Card, Container, Toolbar} from "@mui/material";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import SelectFormControl from "../../components/form-helpers/SelectFormControl";
import CheckboxFormControl from "../../components/form-helpers/CheckboxFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";

const UsersDetailsPage = () => {
  const [user, setUser] = useState<any>({} as any);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any>([]);

  const {id} = useParams();

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      user_id: 0,
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      id_card_series: "",
      id_card_number: "",
      id_card_issued_by: "",
      role: "",
      changePassword: false,
      newPassword: "",
      newPasswordCheck: "",
      vehicle: {}
    }
  });

  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/users/${id}`).then((res) => {
      setUser(res);
      setVehicles([res?.UserVehicles?.[0]?.vehicle]);

      reset({
        user_id: res.user_id || "",
        email: res.email || "",
        first_name: res.first_name || "",
        last_name: res.last_name || "",
        phone: res.phone || "",
        id_card_series: res.id_card_series || "",
        id_card_number: res.id_card_number || "",
        id_card_issued_by: res.id_card_issued_by || "",
        role: res?.UserRoles[0]?.role || "",
        changePassword: false,
        vehicle: res?.UserVehicles?.[0]?.vehicle
      })
    }).catch((err) => {
      toast.error(err);
    });
  }, []);

  const onVehicleInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get('/autofleet/options').then((res) => {
        setVehicles(res);
      }).catch((err) => {
        toast.error(err.message);
      });
    }
  }

  const onVehicleChange = (event: any, value: any) => {
    setValue('vehicle', value);
  }

  const handleUserUpdate = (data: FieldValues) => {

    if (data.changePassword) {
      if (data.newPassword != data.newPasswordCheck) {
        toast('Parolele nu corespund', {type: 'error'});
        return;
      }
      if (!data.newPassword) {
        toast('Parola noua este obligatorie', {type: 'error'});
        return;
      }

      api.put('/users/password', data).then((res) => {
        toast.success('Parola actualizata cu succes.');
      }).catch((err) => {
        toast.error(err.message);
      })
    }

    const originalRole = user?.UserRoles[0]?.role;
    if (data.role != originalRole) {
      if (!originalRole) {
        const newRole = {user_id: user.user_id, role: data.role};
        api.post('/users/role', newRole).then((res) => {
          toast.success('Rol adaugat cu succes.');
        }).catch((err) => {
          toast.error(err.message);
        })
      } else {
        const newRole = {user_role_id: user.UserRoles[0].user_role_id, role: data.role};
        api.put('/users/role', newRole).then((res) => {
          toast.success('Rol actualizat cu succes.');
        }).catch((err) => {
          toast.error(err.message);
        })
      }
    }

    api.put('/users', data).then((res) => {
      navigate('/users');
      toast.success('Utilizator actualizat cu succes.');
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  const toggleEdit = () => {
    setEditMode(!editMode);
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Toggle edit</Button>
        </Card>
      </Toolbar>
      <form onSubmit={handleSubmit(handleUserUpdate)}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control}
                            name='email'
                            displayText='Email'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
                            rules={{
                              required: {value: true, message: 'Email-ul este obligatoriu.'},
                              pattern: /^\S+@\S+$/i
                            }}
          />
          <BasicFormControl control={control}
                            name='last_name'
                            displayText='Nume'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
                            rules={{
                              required: {value: true, message: 'Numele este obligatoriu.'}
                            }}/>
          <BasicFormControl control={control}
                            name='first_name'
                            displayText='Prenume'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
                            rules={{
                              required: {value: true, message: 'Prenumele este obligatoriu.'}
                            }}/>
          <BasicFormControl control={control}
                            name='phone'
                            displayText='Telefon'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
          />
          {vehicles?.length > 0 &&
              <AutocompleteFormControl control={control}
                                       name='vehicle'
                                       displayText='Numar de inmatriculare'
                                       options={vehicles}
                                       onChangeHandler={onVehicleChange}
                                       onInputHandler={onVehicleInput}
                                       optionLabelFunc={(vehicles) => vehicles?.reg_no || ""}
                                       errors={errors}
                                       defaultValue={{
                                         auto_fleet_id: vehicles?.[0]?.auto_fleet_id || '',
                                         reg_no: vehicles?.[0]?.reg_no || ''
                                       }}
                                       disabled={!editMode}
              />
          }
        </Box>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control}
                            name='id_card_series'
                            displayText='Serie CI'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
          />
          <BasicFormControl control={control}
                            name='id_card_number'
                            displayText='Numar CI'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
          />
          <BasicFormControl control={control}
                            name='id_card_issued_by'
                            displayText='Emitent CI'
                            errors={errors}
                            variant='outlined'
                            disabled={!editMode}
          />
          <SelectFormControl control={control} name='role'
                             selectOptions={[{label: 'Administrator', value: '1'}, {
                               label: 'Operator',
                               value: '2'
                             }, {label: 'Sofer', value: '3'}]}
                             displayText='Rol'
                             rules={{required: {value: true, message: 'Rolul este obligatoriu'}}}
                             disabled={!editMode}
                             errors={errors}/>

        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <CheckboxFormControl control={control}
                               name='changePassword'
                               displayText='Schimba Parola'
                               errors={errors}
                               variant='outlined'
                               type="password"
                               disabled={!editMode}

          />
          <BasicFormControl control={control}
                            name='newPassword'
                            displayText='Parola'
                            errors={errors}
                            variant='outlined'
                            type="password"
                            disabled={!editMode}
          />
          <BasicFormControl control={control}
                            name='newPasswordCheck'
                            displayText='Reintrodu Parola'
                            errors={errors}
                            variant='outlined'
                            type="password"
                            disabled={!editMode}
          />
        </Box>

        {editMode &&
            <Button disabled={!editMode} type='submit' variant='contained'
                    sx={{width: '100%', my: 2}}>Submit</Button>
        }
      </form>
    </Container>
  )
}

export default UsersDetailsPage;
