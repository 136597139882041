import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Table from "../../components/table/Table";
import {InvoiceTableHeaders} from "./InvoiceTableConstants";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {Close} from "@mui/icons-material";
import PdfTemplate from "../../components/pdf-templates/PdfTemplate";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {FieldValues} from "react-hook-form";
import CreateReceivedInvoiceForm from "../../components/forms/CreateReceivedInvoiceForm";
import DialogContainer from "../../components/DialogContainer";
import CreateOperationForm from "../../components/forms/CreateOperationForm";
import InvoicePdfTemplate from "../../components/pdf-templates/InvoicePdfTemplate";
import Filters from "../../components/filters/Filters";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";

const ReceivedInvoicesPage = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(undefined);
  const [pdfInvoiceId, setPdfInvoiceId] = useState<number>(0);
  const [exportToPdfDialogOpen, setExportToPdfDialogOpen] = useState<boolean>(false);
  const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
  const [createInvoiceDialogOpen, setCreateInvoiceDialogOpen] = useState<boolean>(false);
  const [invoicePaymentDialogOpen, setInvoicePaymentDialogOpen] = useState(false);
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>();
  const [reset, setReset] = useState<number>(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const filterable: FilterSettings[] = [
    {
      key: 'series',
      display: 'Serie',
      type: 'text',
    },
    {
      key: 'number',
      display: 'Numar',
      type: 'number'
    },
    {
      key:'created_from',
      display: 'Data emitere (de la)',
      type: 'date'
    },
    {
      key:'created_to',
      display:'Data emitere (pana la)',
      type: 'date'
    },
    {
      key:'status',
      display:'Status',
      type: 'text'
    }
  ];

  const actions: any[] = [
    {
      name: 'handleOperation',
      displayName: 'Plateste factura',
      action: handleInvoicePayment,
    },
    {
      name: 'handleExportToPdf',
      displayName: 'Export to PDF',
      action: handleExportToPdf
    },
    {
      name: 'deleteInvoiceRow',
      displayName: 'Delete row',
      action: handleDeleteRowRequest
    }
  ]

  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location]);

  useEffect(() => {
    api.get(`/invoices${queryParams || '?'}&type=received`).then((res: any) => {
      res.forEach((resItem: any) => {
        resItem.buyer = resItem?.buyer?.name
      })

      setInvoices(res);
    })
  }, [queryParams, reset]);

  useEffect(() => {
    setQueryParams(new URLSearchParams(filters).toString());
    navigate(`?${new URLSearchParams(filters).toString()}`);
  }, [filters])

  function handleExportToPdf(row: any) {
    api.get(`/invoices/${row.invoice_id}`).then((res: any) => {
      setSelectedInvoice(res);
      setExportToPdfDialogOpen(true);
    }).catch((err) => {
      toast.error(err);
    })
  }

  function handleDeleteRowRequest(row: any) {
    setRowDeleteRequested(row);
  }

  function handleInvoicePayment(row: any) {
    setSelectedInvoice(row);
    setInvoicePaymentDialogOpen(true);
  }

  const handleDeleteRow = () => {
    api.remove(`/invoices/${rowDeleteRequested.invoice_id}`).then((res) => {
      toast.success(res.message);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err);
    }).finally(() => {
      setRowDeleteRequested(undefined);
    })
  }

  const rowDeleteCanceled = (row: any) => {
    setRowDeleteRequested(null);
  }

  const openCreateInvoiceDialog = () => {
    setCreateInvoiceDialogOpen(true);
  }

  const handleCreateInvoice = (data: FieldValues) => {
    api.post('/invoices', data).then((res) => {
      toast.success('Invoice successfully added');
      setCreateInvoiceDialogOpen(false);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message || 'Something went wrong!');
    })
  }

  const handleCreateOperation = (data: FieldValues) => {
    api.post('/operation', data).then(res => {
      toast.success("Plata a fost creata cu succes");
      setInvoicePaymentDialogOpen(false);
      setReset(reset + 1);
    }).catch(err => {
      toast.error(err.message);
    })
  }

  const formatCell = (row: any, value: string) => {
    return <Link to={`/invoices/${row.invoice_id}`}>{value}</Link>
  }

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Table headers={InvoiceTableHeaders}
               rows={invoices}
               actions={actions}
               handleExportToPdf={handleExportToPdf}
               getFormattedCell={formatCell}
               handleAddActionClick={openCreateInvoiceDialog}
        ></Table>
      </Box>

      <DialogContainer
          dialogTitle="Plateste factura"
          isDialogOpen={invoicePaymentDialogOpen}
          handleDialogState={setInvoicePaymentDialogOpen}
      >
        <CreateOperationForm invoice={selectedInvoice} onReceiptSubmit={handleCreateOperation} paymentType="PLATA"/>
      </DialogContainer>

      <Dialog
        fullScreen={fullScreen}
        open={createInvoiceDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant='h5'>Adauga factura furnizor</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setCreateInvoiceDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <CreateReceivedInvoiceForm onInvoiceInput={handleCreateInvoice}/>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={exportToPdfDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setExportToPdfDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <PdfTemplate invoiceData={selectedInvoice}
                       exportName={`Factura-${selectedInvoice?.series}-${selectedInvoice?.number}`}
                       handleDialogOpenState={(value) => setExportToPdfDialogOpen(value)}>
            <InvoicePdfTemplate invoiceData={selectedInvoice} />
          </PdfTemplate>
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
                                deleteConfirmationText={rowDeleteRequested?.number + rowDeleteRequested?.series}
                                handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled}/>

    </Container>
  )
}

export default ReceivedInvoicesPage;