import {Box, Container} from "@mui/material";
import {useEffect, useState} from "react";
import * as api from "../../api/api-client";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {KeyValuePair} from "../../types/CommonTypes";
import Table from "../../components/table/Table";
import DialogContainer from "../../components/DialogContainer";
import CreatePartnerForm from "../../components/forms/CreatePartnerForm";
import {FieldValues} from "react-hook-form";
import {toast} from "react-toastify";
import {FilterSettings} from "../../types/FilterTypes";
import Filters from "../../components/filters/Filters";
import AddPartnerCommentForm from "../../components/forms/AddPartnerCommentForm";


const PartnerPage = () => {
  const [addPartnerDialogOpen, setAddPartnerDialogOpen] = useState<boolean>(false);
  const [headers, setHeaders] = useState<any>([
    {
      id: "name",
      label: "Nume",
      width: 150,
      type: 'href'
    },
    {
      id: "address",
      label: "Adresa",
      width: 250,
    },
    {
      id: "unique_identification_number",
      label: "CUI",
      width: 100,
    },
    {
      id: "trade_register_registration_number",
      label: "Reg. Comert",
      width: 100,
    },
    {
      id: "invoice_deadline_days",
      label: "Zile scadenta facturi",
      width: 100
    },
    {
      id: "type",
      label: "Tip",
      width: 100
    }
  ]);
  const [partners, setPartners] = useState<any[]>([]);
  const [addPartnerCommentDialogOpen, setAddPartnerCommentDialogOpen] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<any>();

  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>();

  const filterable: FilterSettings[] = [
    {
      key: 'name',
      display: 'Nume',
      type: 'text',
    },
    {
      key: 'unique_identification_number',
      display: 'CUI',
      type: 'text'
    },
    {
      key: 'type',
      display: 'Tip partener',
      type: 'dropdown',
      dropdownValues: [
        {label: 'Client', value: 'Client'},
        {label: 'Transportator', value: 'Transportator'},
        {label: 'Prospect', value: 'Prospect'}
      ]
    }
  ]

  const navigate = useNavigate();

  const actions = [
    {
      name: 'handleAddPartnerComment',
      displayName: 'Adauga comentariu',
      action: handleAddPartnerComment
    }
  ]

  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location])

  useEffect(() => {
    api.get(`/partners${queryParams}`).then((res: any) => {
      setPartners(res);
    })
  }, [queryParams]);

  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  const handleAddPartner = () => {
    setAddPartnerDialogOpen(true);
  }

  const handleAddPartnerDialogOpen = (dialogState: boolean) => {
    setAddPartnerDialogOpen(dialogState);
  }

  const handlePartnerSubmit = (data: FieldValues) => {
    api.post('/partners', data).then((res) => {
      toast.success('Partener adaugat cu succes');
      setAddPartnerDialogOpen(false);
    }).catch((err) => {
      toast.error(err?.message);
    })
  }

  const formatCell = (row: any, value: string) => {
    return <Link to={`${row.partner_id}`}>{value}</Link>
  }

  const handleAddPartnerCommentDialogState = (dialogState: boolean) => {
      setAddPartnerCommentDialogOpen(dialogState);
  }

  const handleAddPartnerCommentSubmit = (data: FieldValues) => {
    data.partner_id = selectedPartner.partner_id;

    api.post('/partners/comments', data).then((res) => {
      toast.success('Partenerul a fost adaugat');
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      setAddPartnerCommentDialogOpen(false);
    })
  }

  function handleAddPartnerComment(row: any) {
    setSelectedPartner(row);
    setAddPartnerCommentDialogOpen(true);
  }

  return (
    <>
      <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
        <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>

        <Box sx={{width: '100%', height: '100%', maxHeight: '100%', marginTop: '2rem'}}>
          <Table headers={headers} rows={partners} getFormattedCell={formatCell}
                 handleAddActionClick={handleAddPartner} actions={actions}/>
        </Box>

        <DialogContainer isDialogOpen={addPartnerCommentDialogOpen} dialogTitle='Adauga comentariu'
                         handleDialogState={handleAddPartnerCommentDialogState}>
          <AddPartnerCommentForm onCommentSubmit={handleAddPartnerCommentSubmit}/>
        </DialogContainer>

        <DialogContainer isDialogOpen={addPartnerDialogOpen} dialogTitle='Adauga partener'
                         handleDialogState={handleAddPartnerDialogOpen}>
          <CreatePartnerForm onPartnerInput={handlePartnerSubmit}/>
        </DialogContainer>
      </Container>
    </>
  )
};

export default PartnerPage;