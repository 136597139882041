import {FieldValues, useForm} from "react-hook-form";
import {Button} from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import {useEffect} from "react";

interface Props {
  existingBankAccount?: any;
  onBankAccountSubmit: (data: FieldValues) => void;
}

const CreateBankAccountForm = (props: Props) => {
  const {
    handleSubmit,
    formState: {errors},
    control,
    reset
  } = useForm(
    {
      defaultValues: {
        iban: '',
        bank_name: '',
        currency: ''
      }
    }
  );

  useEffect(() => {
    if (props.existingBankAccount) {
      reset(props.existingBankAccount);
    }
  }, [props.existingBankAccount])

  const handleBankAccountSubmit = (data: FieldValues) => {
    props.onBankAccountSubmit(data);
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleBankAccountSubmit)}>
        <BasicFormControl control={control} name='iban' displayText='IBAN' variant='outlined' errors={errors}
                          rules={{
                            required: {value: true, message: 'IBAN is required'}
                          }}/>
        <BasicFormControl control={control} name='bank_name' displayText='Bank name' variant='outlined' errors={errors}
                          rules={{
                            required: {value: true, message: 'Bank name is required'}
                          }}/>
        <BasicFormControl control={control} name='currency' displayText='Currency' variant='outlined' errors={errors}
                          rules={{
                            required: {value: true, message: 'Currency is required'}
                          }}/>

        <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
      </form>
    </>
  )
}

export default CreateBankAccountForm;