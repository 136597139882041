import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Container, Grid} from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import {CashRegisterDto} from "../../types/CashRegisterDto";

interface Props {
    onReceiptSubmit: (data: FieldValues) => void;
    invoice: any
    paymentType: "INCASARE" | "PLATA"
}

const documentTypes = [
    {
        label: "Bon fiscal",
        value: "Bon fiscal"
    },
    {
        label: "OP",
        value: "OP"
    },
    {
        label: "Bilet la ordin",
        value: "Bilet la ordin"
    },
    {
        label: "CEC",
        value: "CEC"
    },
    {
        label: "PV",
        value: "PV"
    },
    {
        label: "Diurna",
        value: "Diurna"
    },
    {
        label: "Bilet de transport",
        value: "Bilet de transport"
    },
    {
        label: "Dispozitie de plata",
        value: "Dispozitie de plata"
    },
    {
        label: "Recuperari",
        value: "Recuperari"
    },
    {
        label: "Dobanda",
        value: "Dobanda"
    },
    {
        label: "Altele",
        value: "Altele"
    },
];

const registerTypes = [
    {
        label: "CASH",
        value: "CASH"
    },
    {
        label: "BANCA",
        value: "BANK"
    }
]

const CreateOperationForm = (props: Props) => {
    const [cashRegisters, setCashRegisters] = useState<any>([]);
    const [bankRegisters, setBankRegisters] = useState<any>([]);
    const [registers, setRegisters] = useState<any>([]);

    const {
        handleSubmit,
        formState: {errors},
        control,
        setValue,
        getValues,
        watch
    } = useForm({
        defaultValues: {
            invoice_id: props.invoice.invoice_id,
            buyer_partner_id: props.invoice.buyer_id,
            cash_register_id: "",
            bank_register_id: "",
            seller_partner_id: props.invoice.client_id,
            currency: props.invoice.currency,
            total_price: Number(props.invoice.total_price_incl_vat - props.invoice.total_paid_price).toFixed(2),
            vat_price: "",
            document_type: "",
            document_number: "",
            description: "",
            issued_date: new Date().toDateString(),
            payment_type: props.paymentType,

            register_type: "CASH",
            register_id: ""
        },
    });

    const issuedDateWatch = watch("issued_date");
    const totalPriceWatch = watch("total_price");
    const registerTypeWatch = watch("register_type");
    const registerWatch = watch("register_id");

    useEffect(() => {
        const partner_id = props.paymentType === "INCASARE"
            ? props.invoice.client_id
            : props.invoice.buyer_id;

        api.get(`/cashregister?partner_id=${partner_id}&currency=${props.invoice.currency}`)
            .then(res => {
                setCashRegisters(res.map((cashRegister: CashRegisterDto) => ({
                    label: `${cashRegister.name} (${cashRegister.currency})`,
                    value: cashRegister.cash_register_id
                })))
            })
            .catch(err => {
                toast(err.message, { type: "error" })
            });

        api.get(`/bankregister?partner_id=${partner_id}&currency=${props.invoice.currency}`)
            .then(res => {
                setBankRegisters(res.map((bankRegister: any) => ({
                    label: `${bankRegister.iban} (${bankRegister.currency})`,
                    value: bankRegister.bank_register_id
                })))
            })
            .catch(err => {
                toast(err.message, { type: "error" })
            });
    }, [props.invoice.invoice_id, props.invoice.client_id, props.invoice.buyer_id]);

    useEffect(() => {
        //setRegisters()
    }, [cashRegisters, bankRegisters])


    useEffect(() => {
        setValue("issued_date", new Date(issuedDateWatch).toDateString());
    }, [issuedDateWatch]);

    useEffect(() => {
        // @ts-ignore
        setValue("cash_register_id", undefined);
        // @ts-ignore
        setValue("bank_register_id", undefined);
        setValue("register_id", "");
    }, [registerTypeWatch])

    useEffect(() => {
        if(registerTypeWatch === "CASH") {
            setValue("cash_register_id", registerWatch);
        } else {
            setValue("bank_register_id", registerWatch);
        }
    }, [registerWatch])

    useEffect(() => {
        const formValues = getValues();

        if(formValues.currency === "RON") {
            setValue("vat_price", Number(parseFloat(totalPriceWatch) * 19 / 100).toFixed(2));
        } else {
            setValue("vat_price", "0");
        }
    }, [totalPriceWatch]);

    const handleReceiptSubmit = (data: FieldValues) => {
        props.onReceiptSubmit(data);
    }

    return (
        <form onSubmit={handleSubmit(handleReceiptSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <DatePickerFormControl control={control} name="issued_date" displayText="Data document" variant='outlined' />
                    <SelectFormControl control={control} name='document_type' displayText='Tip document' variant='outlined' errors={errors}
                                       selectOptions={documentTypes}
                                       rules={{
                                           required: {value: true, message: 'Tip document este obligatoriu'}
                                       }}/>
                    <BasicFormControl control={control} name="document_number" displayText="Nr document" variant="outlined" />
                </Box>

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <SelectFormControl control={control} name="register_type" selectOptions={registerTypes} displayText="Tip casa" variant="outlined"/>
                    <SelectFormControl control={control} name='register_id' displayText='Casa' variant='outlined' errors={errors}
                                       selectOptions={registerTypeWatch === "CASH" ? cashRegisters : bankRegisters}
                                       rules={{
                                           required: {value: true, message: 'Casa este obligatorie'}
                                       }}/>
                </Box>

                <Box sx={{ display: 'flex', width: '100%'}}>
                    <BasicFormControl multiline rows={4} control={control} name="description" displayText="Detalii plata" variant='outlined' />
                </Box>

                <Box sx={{ display: 'flex', width: '100%'}}>
                    <BasicFormControl control={control} type="number" name="total_price" displayText="Valoare" variant='outlined' errors={errors}
                                      adornment={props.invoice.currency}
                                      rules={{
                                          required: {value: true, message: 'Valoarea este obligatorie.'},
                                          validate: (field) => parseFloat(field) < parseFloat(props.invoice.total_price_incl_vat) - parseFloat(props.invoice.total_paid_price) + 0.001
                                              || "Valoarea platita trebuie sa fie mai mica decat suma totala."
                                      }}/>

                    <BasicFormControl control={control} type="number" name="vat_price" displayText="Din care TVA" variant='outlined'
                                      disabled
                                      adornment={props.invoice.currency}/>
                </Box>
            </Box>
            <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
        </form>

    )
}

export default CreateOperationForm;