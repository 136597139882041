import { Container, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as api from "../../api/api-client";
import CreateProductForm from "../../components/forms/CreateProductForm";

const CreateProductPage = () => {
    const navigate = useNavigate();

    const onProductSubmit = (data: any) => {
        api.post('/products', data).then((res) => {
            toast.success('Produs adaugat cu success');
            navigate('/products');
        }).catch((err) => {
            toast.error('Something went wrong!');
        })
    }

    return (
        <Container sx={{ maxWidth: '100vw !important', height: '100%', m: 0, mt: 4 }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <h2>Adauga un produs</h2>
            </Box>
            <Box>
                <CreateProductForm onProductInput={onProductSubmit} />
            </Box>
        </Container>
    )
}

export default CreateProductPage;