import {useEffect, useState} from "react";
import * as api from "../../api/api-client";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Card, Container, Toolbar} from "@mui/material";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";

const AutoFleetDetailsPage = () => {
  const [autoFleet, setAutoFleet] = useState<any>({} as any);
  const [partners, setPartners] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const {id} = useParams();

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset,
    watch
  } = useForm({
    defaultValues: {
      reg_no: "",
      partner: "",
      vin: "",
      model: "",
      vignette_ro: "",
      itp: "",
      cmr_insurance: "",
      rca: "",
      carbon_copy: "",
      vignette_hu: "",
      vignette_slo: "",
      casco: "",
      partner_id: "",
      auto_fleet_id: "",
      max_weight_in_tons: 0
    }
  });

  const watchRegNo = watch('reg_no');
  const watchVin = watch('vin');

  useEffect(() => {
    if (watchRegNo) {
      setValue('reg_no', watchRegNo.replace(/ /g, '').toUpperCase());
    }
  }, [watchRegNo]);

  useEffect(() => {
    if (watchVin) {
      setValue('vin', watchVin.replace(/ /g, '').toUpperCase());
    }
  }, [watchVin])

  useEffect(() => {
    api.get(`/autofleet/${id}`).then((res) => {
      setAutoFleet(res);

      reset({
        reg_no: res.reg_no || "",
        vin: res.vin || "",
        model: res.model || "",
        vignette_ro: res.vignette_ro || "",
        itp: res.itp || "",
        cmr_insurance: res.cmr_insurance || "",
        rca: res.rca || "",
        carbon_copy: res.carbon_copy || "",
        vignette_hu: res.vignette_hu || "",
        vignette_slo: res.vignette_slo || "",
        casco: res.casco || "",
        partner_id: res.partner_id || "",
        partner: res.partner || "",
        auto_fleet_id: res.auto_fleet_id || "",
        max_weight_in_tons: res.max_weight_in_tons || 0
      })
    }).catch((err) => {
      toast.error(err);
    });
  }, []);

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner', value.partner_id);
    setValue('partner_id', value.partner_id);
  }

  const handleAutoFleetUpdate = (data: FieldValues) => {
    api.put('/autofleet', data).then((res) => {
      toast.success(res.message);
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  const toggleEdit = () => {
    setEditMode(!editMode);
  }


  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Editare</Button>
        </Card>
      </Toolbar>
      <form onSubmit={handleSubmit(handleAutoFleetUpdate)}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          {autoFleet.partner &&
              <AutocompleteFormControl control={control}
                                       name='partner'
                                       displayText='Firma'
                                       options={partners}
                                       optionLabelFunc={(partners) => partners?.partner_name || ""}
                                       onInputHandler={onPartnerAutocompleteInput}
                                       onChangeHandler={onPartnerAutocompleteChange}
                                       disabled={!editMode}
                                       rules={{
                                         required: {value: true, message: 'Firma este obligatorie.'}
                                       }}
                                       defaultValue={{
                                         partner_id: autoFleet?.partner?.partner_id,
                                         partner_name: autoFleet?.partner?.name
                                       } || ""}
              />
          }
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control}
                            name='reg_no'
                            displayText='Numar de inmatriculare'
                            errors={errors}
                            disabled={!editMode}
                            rules={{
                              required: {value: true, message: 'Numarul de inmatriculare este obligatoriu.'}
                            }}/>
          <BasicFormControl control={control}
                            name='vin'
                            displayText='Serie sasiu'
                            errors={errors}
                            disabled={!editMode}
                            rules={{
                              required: {value: true, message: 'Seria de sasiu este obligatorie.'},
                              maxLength: {value: 17, message: 'Seria de sasiu este formata din 17 caractere'},
                              minLength: {value: 17, message: 'Seria de sasiu este formata din 17 caractere'}
                            }}/>
          <BasicFormControl control={control}
                            name='model'
                            displayText='Denumire vehicul'
                            errors={errors}
                            disabled={!editMode}
                            rules={{
                              required: {value: true, message: 'Campul este obligatoriu.'}
                            }}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <DatePickerFormControl control={control} name='vignette_ro' displayText='Rovinieta' disabled={!editMode}/>
          <DatePickerFormControl control={control} name='vignette_hu' displayText='Vinieta HU' disabled={!editMode}/>
          <DatePickerFormControl control={control} name='vignette_slo' displayText='Vinieta SLO' disabled={!editMode}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <DatePickerFormControl control={control} name='itp' displayText='ITP' disabled={!editMode}/>
          <DatePickerFormControl control={control} name='cmr_insurance' displayText='Asigurare CMR'
                                 disabled={!editMode}/>
          <DatePickerFormControl control={control} name='rca' displayText='RCA' disabled={!editMode}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <DatePickerFormControl control={control} name='carbon_copy' displayText='Copie conforma'
                                 disabled={!editMode}/>
          <DatePickerFormControl control={control} name='casco' displayText='Casco' disabled={!editMode}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control}
                            name='max_weight_in_tons'
                            displayText='Tonaj maxim'
                            errors={errors}
                            disabled={!editMode}
                            type='number'
                            rules={{
                              required: {value: true, message: 'Tonajul maxim este obligatoriu.'}
                            }}/>
        </Box>

        {editMode &&
            <Button disabled={!editMode} type='submit' variant='contained' sx={{width: '100%', my: 2}}>Submit</Button>
        }
      </form>
    </Container>
  )
}

export default AutoFleetDetailsPage;
