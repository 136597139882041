import {Box, Button, Card, Container, Toolbar, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

import * as api from '../../api/api-client';
import Table from "../../components/table/Table";
import CreateBankAccountForm from "../../components/forms/CreateBankAccountForm";
import {FieldValues, useForm} from "react-hook-form";
import CreateAddressForm from "../../components/forms/CreateAddressForm";
import DialogContainer from "../../components/DialogContainer";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import CheckboxFormControl from "../../components/form-helpers/CheckboxFormControl";
import SelectFormControl from "../../components/form-helpers/SelectFormControl";
import HorizontalMenu from "../../components/HorizontalMenu";
import Divider from "@mui/material/Divider";
import PartnerComments from "../../components/partners/PartnerComments";
import CreateContactForm from "../../components/forms/CreateContactForm";

enum DialogUpdateFields {
  bankAccounts = "bankAccounts",
  contacts = "contacts",
  addresses = "addresses"
}

const PartnerDetailsPage = () => {
  const [partner, setPartner] = useState<any>({});
  const [partnerAddresses, setPartnerAddresses] = useState<any>([]);
  const [partnerBankAccounts, setPartnerBankAccounts] = useState<any>([]);
  const [partnerContacts, setPartnerContacts] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [resetData, setResetData] = useState<number>(0);

  const [isUpdateAddressDialogOpen, setIsUpdateAddressDialogOpen] = useState<boolean>(false);
  const [isUpdateBankAccountDialogOpen, setIsUpdateBankAccountDialogOpen] = useState<boolean>(false);
  const [isUpdateContactDialogOpen, setIsUpdateContactDialogOpen] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [selectedBankAccount, setSelectedBankAccount] = useState<any>({});
  const [selectedContact, setSelectedContact] = useState<any>({});
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');

  const [isBankAccountDialogOpen, setIsBankAccountDialogOpen] = useState<boolean>(false);
  const [isAddressesDialogOpen, setIsAddressesDialogOpen] = useState<boolean>(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>({});

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      unique_identification_number: '',
      trade_register_registration_number: '',
      address: '',
      vat_payer: false,
      vat_split: false,
      vat_collection: false,
      type: '',
      invoice_deadline_days: ''
    }
  });

  const addressHeaders = [
    {
      id: 'nickname',
      width: 100,
      label: 'Nickname'
    },
    {
      id: 'address',
      width: 100,
      label: 'Addresa',
    },
    {
      id: 'county',
      width: 100,
      label: 'Judet',
    },
    {
      id: 'country',
      width: 100,
      label: 'Tara'
    },
    {
      id: 'city',
      width: 100,
      label: 'Oras'
    }
  ];

  const bankAccountHeaders = [
    {
      id: 'iban',
      width: 100,
      label: 'IBAN'
    },
    {
      id: 'bank_name',
      width: 100,
      label: 'Banca'
    },
    {
      id: 'currency',
      width: 100,
      label: 'Moneda'
    }
  ];

  const contactHeaders = [
    {
      id: 'first_name',
      width: 100,
      label: 'Prenume'
    },
    {
      id: 'last_name',
      width: 100,
      label: 'Nume'
    },
    {
      id: 'phone',
      width: 100,
      label: 'Telefon'
    },
    {
      id: 'contact_email',
      width: 100,
      label: 'E-mail'
    }
  ];

  let {id} = useParams();

  const addressActions = [
    {
      name: 'updateAddress',
      displayName: 'Modifica adresa',
      action: updateAddress
    },
    {
      name: 'deleteAddress',
      displayName: 'Sterge adresa',
      action: deleteAddress
    }
  ]

  const contactActions = [
    {
      name: 'updateContact',
      displayName: 'Modifica contact',
      action: updateContact
    },
    {
      name: 'deleteContact',
      displayName: 'Sterge contact',
      action: deleteContact
    }
  ]

  const bankActions = [
    {
      name: 'updateBankAccount',
      displayName: 'Modifica cont',
      action: updateBankAccount
    },
    {
      name: 'deleteBankAccount',
      displayName: 'Sterge cont',
      action: deleteBankAccount
    }
  ]

  useEffect(() => {
    api.get(`/partners/${id}`).then((res) => {
      setPartner(res);

      reset({...res});

      setPartnerAddresses(res.Addresses);
      setPartnerBankAccounts(res.BankAccounts);
      setPartnerContacts(res.Contacts)
    }).catch((err) => {
      toast(err, {position: 'bottom-right', type: 'error'})
    });
  }, [resetData]);

  const toggleEdit = () => {
    setEditMode(!editMode)
  }

  const submitAddress = (data: FieldValues) => {
    data.partner_id = partner.partner_id;
    data.address = data.address_point;

    api.post('/partners/address', data).then((res) => {
      toast.success("Adresa adaugata cu success");
      setIsAddressesDialogOpen(false);
      setResetData(resetData + 1);
    }).catch((err) => {
      toast.error(err);
    });
  }

  const submitBankAccount = (data: FieldValues) => {
    data.partner_id = partner.partner_id;

    api.post('/partners/bankaccounts', data).then((res) => {

      toast.success("Cont bancar adaugat cu success");
      setIsBankAccountDialogOpen(false);

      setResetData(resetData + 1);
    }).catch((err) => {
      toast.error(err);
    });
  }

  const submitContact = (data: FieldValues) => {
    api.post('/partners/contacts', data)
      .then(res => {
        toast.success("Contactul a fost adaugat cu succes.");
        setIsContactDialogOpen(false);

        setResetData(resetData + 1);
      })
      .catch(err => {
        toast.error(err);
      });
  }

  const updatePartnerAddress = (fieldValues: FieldValues) => {
    fieldValues.address = fieldValues.address_point;

    api.put('/partners/address', fieldValues)
      .then(() => {
        setResetData(resetData + 1);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setSelectedAddress({});
        handleUpdateAddressDialogState(false);
      });
  }

  const updatePartnerBankAccount = (fieldValues: FieldValues) => {
    return api.put('/partners/bankaccounts', fieldValues)
      .then(() => {
        setResetData(resetData + 1);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setSelectedBankAccount({});
        handleUpdateBankAccountDialogState(false);
      });
  }

  const updatePartnerContact = (fieldValues: FieldValues) => {
    api.put('/partners/contacts', fieldValues)
      .then(() => {
        setResetData(resetData + 1)
      })
      .catch(err => {
        toast.error(err)
      })
      .finally(() => {
        setSelectedContact({});
        handleUpdateContactDialogState(false);
      })
  }

  const handleBankAccountDialogState = (dialogState: boolean) => {
    setIsBankAccountDialogOpen(dialogState);
  }

  const handleAddressDialogState = (dialogState: boolean) => {
    setIsAddressesDialogOpen(dialogState);
  }

  const handleContactDialogState = (dialogState: boolean) => {
    setIsContactDialogOpen(dialogState);
  }

  const handleUpdateAddressDialogState = (dialogState: boolean) => {
    setIsUpdateAddressDialogOpen(dialogState);
  }

  const handleUpdateBankAccountDialogState = (dialogState: boolean) => {
    setIsUpdateBankAccountDialogOpen(dialogState);
  }

  const handleUpdateContactDialogState = (dialogState: boolean) => {
    setIsUpdateContactDialogOpen(dialogState);
  }

  const handlePartnerUpdate = (data: FieldValues) => {
    api.put('/partners', data).then((res) => {
      toast.success('Partenerul a fost actualizat');
    }).catch((err) => {
      toast.error(err.message);
    });
  }

  const handleMenuItemClick = (selected: string) => {
    setSelectedMenuItem(selected);
  }

  function updateAddress(row: any) {
    setSelectedAddress(row);
    handleUpdateAddressDialogState(true);
  }

  function updateBankAccount(row: any) {
    setSelectedBankAccount(row);
    handleUpdateBankAccountDialogState(true);
  }

  function updateContact(row: any) {
    setSelectedContact(row);
    handleUpdateContactDialogState(true);
  }

  function deleteContact(row: any) {
    api.remove("/partners/contacts", {contact_id: row.contact_id} as any)
      .then(res => {
        toast.success(res.message);

        setResetData(resetData + 1);
      })
      .catch(err => {
        toast.error(err.message)
      });
  }

  function deleteAddress(row: any) {
    api.remove("/partners/address", {address_id: row.address_id} as any)
      .then(res => {
        toast.success(res.message);

        setResetData(resetData + 1);
      })
      .catch(err => {
        toast.error(err.message)
      });
  }

  function deleteBankAccount(row: any) {
    api.remove("/partners/bankaccounts", {bank_account_id: row.bank_account_id} as any)
      .then(res => {
        toast.success(res.message);

        setResetData(resetData + 1);
      })
      .catch(err => {
        toast.error(err.message)
      });
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Editare</Button>
        </Card>
      </Toolbar>
      <Box sx={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
        {partner &&
            <>
                <form onSubmit={handleSubmit(handlePartnerUpdate)} style={{width: '100%'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                            <BasicFormControl control={control}
                                              name='name'
                                              displayText='Nume'
                                              disabled={!editMode}
                                              rules={{
                                                required: {value: true, message: 'Campul este obligatoriu.'}
                                              }}
                                              errors={errors}
                            />

                            <BasicFormControl control={control}
                                              name='address'
                                              displayText='Adresa'
                                              disabled={!editMode}/>

                        </Box>

                        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                            <BasicFormControl control={control}
                                              name='trade_register_registration_number'
                                              displayText='Nr reg. com.'
                                              rules={{
                                                required: {value: true, message: 'Campul este obligatoriu.'}
                                              }}
                                              errors={errors}
                                              disabled={!editMode}
                            />

                            <BasicFormControl control={control}
                                              name='unique_identification_number'
                                              displayText='CUI'
                                              disabled={!editMode}
                                              rules={{
                                                required: {value: true, message: 'Campul este obligatoriu.'}
                                              }}
                                              errors={errors}
                            />

                            <BasicFormControl control={control} name='invoice_deadline_days'
                                              displayText='Zile scadenta facturi' type='number'
                                              disabled={!editMode}/>

                            <SelectFormControl control={control}
                                               name='type'
                                               selectOptions={[
                                                 {label: 'Client', value: 'Client'},
                                                 {label: 'Prospect', value: 'Prospect'},
                                                 {label: 'Transportator', value: 'Transportator'}
                                               ]}
                                               disabled={!editMode}
                                               displayText='Tip partener'/>
                        </Box>

                        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                            <CheckboxFormControl control={control} name='vat_payer' displayText='Platitor TVA'
                                                 disabled={!editMode}/>
                            <CheckboxFormControl control={control} name='vat_split' displayText='Split TVA'
                                                 disabled={!editMode}/>
                            <CheckboxFormControl control={control} name='vat_collection' displayText='Colectare TVA'
                                                 disabled={!editMode}/>

                        </Box>

                      {editMode && <Button variant='contained' type='submit'>Submit</Button>}
                    </Box>
                </form>

                <Divider sx={{width: '100%'}}/>

                <HorizontalMenu onMenuItemClick={handleMenuItemClick} menuItems={[
                  {label: 'Adrese', value: 'addresses'},
                  {label: 'Conturi', value: 'banks'},
                  {label: 'Comentarii', value: 'comments'},
                  {label: 'Contact', value: 'contacts'}
                ]}>
                    <>
                      {
                        selectedMenuItem === 'addresses' && <Box sx={{width: '100%'}}>
                              <Typography sx={{margin: '1rem'}} variant='h6'>Adrese</Typography>
                              <Table headers={addressHeaders} rows={partnerAddresses} actions={addressActions}
                                     handleAddActionClick={() => setIsAddressesDialogOpen(true)}/>
                          </Box>
                      }
                      {
                        selectedMenuItem === 'banks' && <Box sx={{width: '100%'}}>
                              <Typography sx={{margin: '1rem'}} variant='h6'>Conturi bancare</Typography>
                              <Table headers={bankAccountHeaders} rows={partnerBankAccounts}
                                     actions={bankActions}
                                     handleAddActionClick={() => setIsBankAccountDialogOpen(true)}/>
                          </Box>
                      }
                      {
                        selectedMenuItem === 'comments' && <Box sx={{width: '100%'}}>
                              <PartnerComments partner_id={partner.partner_id}/>
                          </Box>
                      }
                      {
                        selectedMenuItem === 'contacts' && <Box sx={{width: '100%'}}>
                              <Typography sx={{margin: '1rem'}} variant='h6'>Persoane de contact</Typography>
                              <Table
                                  headers={contactHeaders}
                                  rows={partnerContacts}
                                  actions={contactActions}
                                  handleAddActionClick={() => setIsContactDialogOpen(true)}/>
                          </Box>
                      }
                    </>

                </HorizontalMenu>

              {/*<Box width='100%'>*/}
              {/*    <Typography sx={{margin: '1rem'}} variant='h6'>Contact information</Typography>*/}
              {/*    <Table headers={contactDetailsHeaders} rows={partnerContacts}*/}
              {/*           handleAddActionClick={createContactInformation}/>*/}
              {/*</Box>*/}

            </>
        }
      </Box>

      <DialogContainer isDialogOpen={isAddressesDialogOpen}
                       dialogTitle='Adauga adresa partener'
                       handleDialogState={handleAddressDialogState}>
        <CreateAddressForm onAddressSubmit={submitAddress} partner_id={partner?.partner_id}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={isBankAccountDialogOpen}
                       dialogTitle='Adauga cont bancar'
                       handleDialogState={handleBankAccountDialogState}>
        <CreateBankAccountForm onBankAccountSubmit={submitBankAccount}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={isContactDialogOpen}
                       dialogTitle='Adauga persoana de contact'
                       handleDialogState={handleContactDialogState}>
        <CreateContactForm onContactSubmit={submitContact} partner_id={partner.partner_id}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={isUpdateAddressDialogOpen} dialogTitle='Modifica adresa'
                       handleDialogState={handleUpdateAddressDialogState}>
        <CreateAddressForm existingAddress={selectedAddress} onAddressSubmit={updatePartnerAddress}
                           partner_id={partner?.partner_id}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={isUpdateBankAccountDialogOpen} dialogTitle='Modifica cont'
                       handleDialogState={handleUpdateBankAccountDialogState}>
        <CreateBankAccountForm existingBankAccount={selectedBankAccount}
                               onBankAccountSubmit={updatePartnerBankAccount}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={isUpdateContactDialogOpen} dialogTitle='Modifica contact'
                       handleDialogState={handleUpdateContactDialogState}>
        <CreateContactForm onContactSubmit={updatePartnerContact} partner_id={partner.partner_id}
                           existingContact={selectedContact}/>
      </DialogContainer>

      {
        loading && <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}><Typography>Loading...</Typography></Box>
      }
    </Container>
  )
}

export default PartnerDetailsPage;
